import { groupBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Path } from 'react-hook-form';
import { FlexProps } from 'xo/core';
import {
  MaybeMultiOptionTypeValue,
  OptionType,
  OptionTypeValue,
  SPECIAL_CASE_OPTION_VALUES,
  useOnOptionSelected,
} from 'xo/hooks/component-hooks';
import { useFuzzySearchFilteredOptions } from 'xo/hooks/fuzzy-search-hooks';
import { InputVariant } from 'xo/models';
import { setFromValue } from 'xo/utils/option-utils';
import { ConfirmClearProps } from './component-models';
import SelectRenderer from './select-renderer';
import {
  SelectRendererNativeProps,
  SelectRendererWebProps,
} from './select-renderer.props';
import { selectMessages, useOnClear } from './select-utils';

export interface SelectProps<T extends OptionTypeValue> extends FlexProps {
  options: OptionType<T>[];
  onChange?: (value?: MaybeMultiOptionTypeValue) => void;
  onBlur?: () => void;
  value?: MaybeMultiOptionTypeValue;
  isDisabled?: boolean;
  multi?: boolean;
  placeholder?: string;
  icon?: JSX.Element;
  searchable?: boolean;
  searchPaths?: Path<OptionType<T>>[];
  renderSelected?: (
    selectedOptions: OptionType<T>[],
    extra?: any,
  ) => React.ReactNode;
  variant?: InputVariant;
  searchPlaceholder?: string;
  header?: React.ReactNode;
  subHeader?: React.ReactNode;
  clearable?: boolean | ConfirmClearProps;
  nativeProps?: SelectRendererNativeProps;
  webProps?: SelectRendererWebProps;
  // On search, these options will always be at the top, never filtered out
  hoistValuesOnSearch?: OptionTypeValue[];
  onAddSearchStringOption?: (searchString: string) => void;
  testIDPrefix?: string;
  label?: string;
  highlightSelectedOptionsAtStart?: boolean;
  ariaLabel?: string;
}

export const Select = <T extends OptionTypeValue>({
  id,
  options,
  onChange,
  onBlur,
  value,
  isDisabled,
  multi,
  placeholder = selectMessages.defaultPlaceholder,
  searchable,
  searchPaths,
  renderSelected,
  variant,
  clearable = true,
  icon,
  nativeProps,
  webProps,
  hoistValuesOnSearch = SPECIAL_CASE_OPTION_VALUES,
  onAddSearchStringOption,
  testIDPrefix,
  label,
  highlightSelectedOptionsAtStart,
  ariaLabel,
}: SelectProps<T>) => {
  const [searchString, onSearch] = useState<string>();

  const valueSet = setFromValue(value);
  const onOptionSelected = useOnOptionSelected({
    multi,
    value,
    onChange,
    clearable: !!clearable,
  });

  const selectedOptions = options.filter(o =>
    Array.isArray(value) ? valueSet.has(o.value) : value === o.value,
  );

  // the set of options that are shown at the top of a selector should be sticky while the panel is
  // open, and not respond live
  const [valuesSetAtOpen, setValuesSetAtOpen] = useState(valueSet);

  const optionsWithHighlight = useMemo(() => {
    if (!highlightSelectedOptionsAtStart) return options;

    // partition the options to have the selected items first
    const selected: OptionType<T>[] = [];
    const notSelected: OptionType<T>[] = [];
    options.forEach(o =>
      (valuesSetAtOpen.has(o.value) ? selected : notSelected).push(o),
    );
    return selected.concat(notSelected);
  }, [highlightSelectedOptionsAtStart, options, valuesSetAtOpen]);

  const filteredOptions = useFuzzySearchFilteredOptions({
    searchString,
    searchPaths,
    options: optionsWithHighlight,
    enabled: searchable,
    hoistValues: hoistValuesOnSearch,
  });

  const disabled =
    isDisabled || (options.length === 0 && !onAddSearchStringOption);

  const onOpen = () => {
    setValuesSetAtOpen(valueSet);
  };

  const groups = useMemo(
    () => groupBy(filteredOptions, o => o.groupName),
    [filteredOptions],
  );
  const sections = useMemo(
    () =>
      Object.entries(groups).map(([title, data]) => ({
        title,
        data,
      })),
    [groups],
  );

  const onClear = useOnClear({
    multi,
    value,
    onChange,
    options: filteredOptions,
  });

  return (
    <SelectRenderer
      {...{
        valueSet,
        onOptionSelected,
        multi,
        disabled,
        variant,
        sections,
        searchable,
        searchString,
        placeholder,
        selectedOptions,
        filteredOptions,
        options,
        renderSelected,
        onClear,
        onSearch,
        clearable,
        icon,
        nativeProps,
        webProps,
        onAddSearchStringOption,
        testIDPrefix,
        label,
        onOpen,
        ariaLabel,
      }}
    />
  );
};
