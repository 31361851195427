import { Rule } from 'antd/es/form';
import { NamePath, StoreValue } from 'antd/es/form/interface';
import dayjs, { Dayjs } from 'dayjs';
import * as uuid from 'uuid';
import { countryNameByAbbr } from 'xo/utils/zod-utils';
import {
  EMAIL_REGEX,
  IsValidPhoneNumberParams,
  isValidPhoneNumber,
} from 'xo/validation';
import { notify } from '../notifications';

type Store = { getFieldValue: (name: NamePath) => StoreValue };

export const requiredValidator = (label: string): Rule => ({
  required: true,
  message: `${label} is required`,
});

export const notUndefinedValidator = (label: string): Rule => ({
  validator: (_: any, value: any) =>
    value === undefined ? Promise.reject() : Promise.resolve(),
  message: `${label} is required`,
});

export const dateTimeValidator =
  (
    otherFieldName: NamePath,
    message: string,
    isValid: (value: Dayjs, otherValue: Dayjs) => boolean,
  ) =>
  ({ getFieldValue }: Store) => {
    return {
      validator: (_: any, value: Dayjs) => {
        const otherFieldValue = getFieldValue(otherFieldName) as Dayjs | null;
        return otherFieldValue && value && isValid(value, otherFieldValue)
          ? Promise.resolve()
          : Promise.reject();
      },
      message,
    };
  };

export const dateTimeIsSameOrBefore = (
  otherFieldName: NamePath,
  message: string,
  unit?: dayjs.OpUnitType,
) =>
  dateTimeValidator(
    otherFieldName,
    message,
    (value, otherValue) =>
      value.isBefore(otherValue, unit) || value.isSame(otherValue, unit),
  );

export const dateTimeIsSameOrAfter = (
  otherFieldName: NamePath,
  message: string,
  unit?: dayjs.OpUnitType,
) =>
  dateTimeValidator(
    otherFieldName,
    message,
    (value, otherValue) =>
      value.isAfter(otherValue, unit) || value.isSame(otherValue, unit),
  );

export const dateTimeIsBefore = (otherFieldName: NamePath, message: string) =>
  dateTimeValidator(otherFieldName, message, (value, otherValue) =>
    value.isBefore(otherValue),
  );

export const dateTimeIsAfter = (otherFieldName: NamePath, message: string) =>
  dateTimeValidator(otherFieldName, message, (value, otherValue) =>
    value.isAfter(otherValue),
  );

const TIME_FORMAT = 'HH:mm';
const getTime = (value: Dayjs) => dayjs(value.format(TIME_FORMAT), TIME_FORMAT);
export const timeOnlyIsBefore = (otherFieldName: NamePath, message: string) =>
  dateTimeValidator(otherFieldName, message, (value, otherValue) => {
    const result = getTime(value).isBefore(getTime(otherValue));
    return result;
  });

export const timeOnlyIsAfter = (otherFieldName: NamePath, message: string) =>
  dateTimeValidator(otherFieldName, message, (value, otherValue) =>
    getTime(value).isAfter(getTime(otherValue)),
  );

export const checkedValidator = (message: string): Rule => ({
  required: true,
  type: 'enum',
  enum: [true],
  message,
});

export const rangeValidator = (start: number, end: number): Rule => ({
  required: true,
  type: 'integer',
  validator: (_: any, value: number) => {
    return value >= start && value <= end
      ? Promise.resolve()
      : Promise.reject();
  },
  message: `Needs to be between ${start} and ${end}`,
});

export type OneRequired = { name: NamePath; message: string };
export const oneRequiredValidator: (oneRequired: {
  name: NamePath;
  message: string;
}) => Rule =
  ({ name, message }: OneRequired) =>
  ({ getFieldValue }) => ({
    validator(_, value) {
      const otherValue = getFieldValue(name);
      const v = Array.isArray(value) ? value : [value].filter(Boolean);
      const o = Array.isArray(otherValue)
        ? otherValue
        : [otherValue].filter(Boolean);

      if (!v.length && !o.length) {
        return Promise.reject(message);
      }
      return Promise.resolve();
    },
  });

export type BothRequiredWhenOne = { name: NamePath; message: string };
export const bothRequiredWhenOneValidator: (props: {
  name: NamePath;
  message: string;
}) => Rule =
  ({ name, message }: BothRequiredWhenOne) =>
  ({ getFieldValue }) => ({
    validator(_, value) {
      const otherValue = getFieldValue(name);
      if (!value && otherValue) {
        return Promise.reject(message);
      }
      return Promise.resolve();
    },
  });

export const eitherPhoneOrEmailValidator = (name: NamePath, message?: string) =>
  oneRequiredValidator({
    name,
    message: message ?? 'Either Email or Phone is required',
  });

// expects fully internationalised phone numbers, spaces ignored
// to use in mobile, the open API will need to be ported to GraphQL
export const phoneNumberValidator = ({
  countryAbbr,
  requireMobile = true,
}: Omit<IsValidPhoneNumberParams, 'phone'>): Rule => {
  const phoneValidationMessage = `Please provide a valid ${
    countryNameByAbbr[countryAbbr]
  } ${requireMobile ? 'mobile' : 'phone'} number`;

  return {
    validator: async (_, value) => {
      if (!value) {
        // required validation should be handled by a separate validator
        return Promise.resolve();
      }

      const valid = await isValidPhoneNumber({
        phone: value,
        countryAbbr,
        requireMobile,
        onError: notify.error,
      });
      return valid ? Promise.resolve() : Promise.reject();
    },
    message: phoneValidationMessage,
  };
};

export const arrayLengthGreaterThan = (
  length: number,
  message: string,
): Rule => ({
  validator: (_, value) =>
    Array.isArray(value) && value.length > length
      ? Promise.resolve()
      : Promise.reject(),
  message,
});

export const uuidValidator: Rule = {
  validator: (_, value) =>
    !value || uuid.validate(value) ? Promise.resolve() : Promise.reject(),
  message: 'Please provide a valid UUID',
};

export const mustMatchOtherFieldValidator =
  (otherFieldName: NamePath, message: string): Rule =>
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue(otherFieldName) === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(message));
    },
  });

export const emailValidator: Rule = {
  // basic validation: there's an @ with a . after it
  pattern: EMAIL_REGEX,
  message: 'Please provide a valid email address',
};

export const picValidator: Rule = {
  min: 8,
  max: 8,
  message: 'PIC must have 8 characters (letters or numbers)',
};

export const maxLengthValidator = (max: number): Rule => ({
  max,
  type: 'string',
  message: `Must be less than ${max} characters`,
});
