import React from 'react';
import { formatEmailHref } from 'xo/utils/link-utils';
import { TelLink } from './tel-link';

export type ContactPanelType = 'visitor' | 'host' | 'primaryContact';

export interface ContactPanelDetailsProps {
  type: ContactPanelType;
  name?: string;
  phone?: string;
  email?: string;
  size?: 'sm' | 'lg';
  className?: string;
}

export const ContactPanelDetails: React.FC<ContactPanelDetailsProps> = ({
  type,
  name,
  phone,
  email,
}) => {
  const descriptions: Record<ContactPanelType, string> = {
    host: `Please contact your host ${name}`,
    visitor: `Please contact ${name}`,
    primaryContact: `Please contact the site`,
  };

  const contactDetails = phone ? (
    <>
      {' '}
      on <TelLink phoneNumber={phone} underline={true} />
    </>
  ) : email ? (
    <>
      {' '}
      at{' '}
      <a className="text-blue-600 underline" href={formatEmailHref(email)}>
        {email}
      </a>
    </>
  ) : undefined;

  return (
    <>
      Any questions? {descriptions[type]}
      {contactDetails}
    </>
  );
};
