import { cloneElement } from 'react';
import { Pressable } from 'react-native';
import { Box, CenterProps } from 'xo/core';
import { borderRadii } from 'xo/styles/restyle/border-radii';
import { spacing } from 'xo/styles/restyle/spacing';
import { Color, Spacing } from 'xo/styles/restyle/theme';
import { colors } from 'xo/styles/tailwind-theme';

export type IconButtonVariant =
  | 'solid'
  | 'subtle'
  | 'offline'
  | 'grey-dark'
  | 'grey'
  | 'plain';
type IconButtonSize = 'md' | 'lg' | 'xl';
type IconButtonStyle = { bg: Color; fill: string; borderColor?: Color };

const variants: Record<
  IconButtonVariant,
  { base: IconButtonStyle; hovered: IconButtonStyle; pressed: IconButtonStyle }
> = {
  grey: {
    base: { bg: 'grey.200', fill: colors.grey[500] },
    hovered: { bg: 'grey.100', fill: colors.grey[400] },
    pressed: { bg: 'grey.600', fill: colors.white },
  },
  'grey-dark': {
    base: { bg: 'grey.400', fill: colors.white },
    hovered: { bg: 'grey.500', fill: colors.white },
    pressed: { bg: 'grey.600', fill: colors.white },
  },
  plain: {
    base: { bg: 'transparent', fill: colors.black },
    hovered: { bg: 'transparent', fill: colors.black },
    pressed: { bg: 'grey.400', fill: colors.grey[100] },
  },
  offline: {
    base: { bg: 'blue.50', fill: colors.blue[400], borderColor: 'blue.200' },
    hovered: { bg: 'blue.400', fill: colors.white, borderColor: 'blue.100' },
    pressed: {
      bg: 'grey.100',
      fill: colors.blue[600],
      borderColor: 'blue.600',
    },
  },
  subtle: {
    base: { bg: 'transparent', fill: colors.blue[400] },
    hovered: { bg: 'transparent', fill: colors.blue[300] },
    pressed: { bg: 'blue.50', fill: colors.blue[600] },
  },
  solid: {
    base: { bg: 'blue.400', fill: colors.white },
    hovered: { bg: 'blue.200', fill: colors.grey[50] },
    pressed: { bg: 'blue.300', fill: colors.grey[100] },
  },
};

const sizes: Record<IconButtonSize, { width: Spacing; scale: number }> = {
  md: { width: '6', scale: 0.7 },
  lg: { width: '8', scale: 1 },
  xl: { width: '10', scale: 1.2 },
};

export interface IconButtonProps extends CenterProps {
  onPress?: () => void;
  icon: JSX.Element;
  variant?: IconButtonVariant;
  size?: IconButtonSize;
}

export const IconButton = ({
  icon,
  onPress,
  variant = 'plain',
  size = 'lg',
  accessibilityLabel,
  ...rest
}: IconButtonProps) => {
  const style = variants[variant];
  const { width, scale } = sizes[size];

  return (
    <Pressable
      onPress={onPress}
      style={
        typeof spacing[width] === 'number'
          ? {
              width: spacing[width] as number,
              height: spacing[width] as number,
              borderRadius: borderRadii.full,
            }
          : undefined
      }
      accessibilityRole="button"
      accessibilityLabel={accessibilityLabel}
    >
      {({ pressed }) => (
        <Box
          align="center"
          justify="center"
          bg={
            pressed
              ? style.pressed.bg
              : // : isHovered
                // ? style.hovered.bg
                style.base.bg
          }
          borderColor={
            pressed
              ? style.pressed.borderColor ?? style.pressed.bg
              : style.base.borderColor ?? style.base.bg
          }
          borderWidth="2"
          borderStyle="solid"
          rounded="full"
          w={width}
          h={width}
          {...rest}
        >
          {cloneElement(icon, {
            fill: pressed
              ? style.pressed.fill
              : // : isHovered
                // ? style.hovered.fill
                style.base.fill,
            scale,
          })}
        </Box>
      )}
    </Pressable>
  );
};
