import { useCallback } from 'react';
import {
  MaybeMultiOptionTypeValue,
  OptionType,
  OptionTypeValue,
} from 'xo/hooks/component-hooks';

export const selectMessages = {
  defaultPlaceholder: 'Please select',
  nothingToSelect: 'Nothing to select',
  searchForOption: 'Start typing to find option',
  noOptionsFound: (searchString?: string) =>
    `No options found for '${searchString}'`,
  noOptionsAddable: (label?: string) =>
    `No options available. Please add ${label ?? 'your own'}.`,
  noOptionsAddRequiredPlaceholder: `Start typing to add option`,
};

export interface UseOnClear<T extends OptionTypeValue> {
  options: OptionType<T>[];
  onChange?: (value?: MaybeMultiOptionTypeValue) => void;
  value?: MaybeMultiOptionTypeValue;
  multi?: boolean;
}

export const useOnClear = <T extends OptionTypeValue>({
  options,
  multi,
  value,
  onChange,
}: UseOnClear<T>) => {
  const isOptionDisabled = useCallback(
    (value: OptionTypeValue) =>
      options.some(o => o.value === value && o.disabled),
    [options],
  );

  return useCallback(() => {
    const newValues =
      multi && Array.isArray(value)
        ? value.filter(isOptionDisabled)
        : multi
          ? []
          : isOptionDisabled(value as OptionTypeValue)
            ? value
            : null;
    onChange && onChange(newValues);
  }, [onChange, isOptionDisabled, value, multi]);
};
