import { ApiTransportUserKind } from 'xo/graphql/api/enums/transport-user-kind.generated';
import { ApiUserKind } from 'xo/graphql/api/enums/user-kind.generated';
import { ApiUserStatus } from 'xo/graphql/api/enums/user-status.generated';

import { Dayjs } from 'dayjs';
import gql from 'graphql-tag';
import {
  ApiOrganisationSummaryFragment,
  OrganisationSummaryFragmentDoc,
} from './organisation-summary-fragment.generated';
import {
  ApiSiteBriefSummaryFragment,
  SiteBriefSummaryFragmentDoc,
} from './site-brief-summary-fragment.generated';
import {
  ApiUserPreferencesFragment,
  UserPreferencesFragmentDoc,
} from './user-preferences-fragment.generated';
import {
  ApiUserRoleFragment,
  UserRoleFragmentDoc,
} from './user-role-fragment.generated';
export type ApiUserFragment = {
  __typename?: 'User';
  id: string;
  kind: ApiUserKind;
  loginPhone?: string;
  preferredPhone?: string;
  explicitPreferredPhone?: string;
  email?: string;
  name: string;
  timezone?: string;
  transportKinds: Array<ApiTransportUserKind>;
  airtableDriverId?: string;
  createdAt?: Dayjs;
  onboardedAtDate?: Dayjs;
  features: Array<string>;
  canResetPassword: boolean;
  lastSeen?: Dayjs;
  status: ApiUserStatus;
  editable: boolean;
  hostAtSites: Array<{ __typename?: 'Site' } & ApiSiteBriefSummaryFragment>;
  transportAtSites: Array<
    { __typename?: 'Site' } & ApiSiteBriefSummaryFragment
  >;
  primaryContactAtSites: Array<
    { __typename?: 'Site' } & ApiSiteBriefSummaryFragment
  >;
  organisation?: {
    __typename?: 'Organisation';
  } & ApiOrganisationSummaryFragment;
  preferences: { __typename?: 'UserPreferences' } & ApiUserPreferencesFragment;
  roles: Array<{ __typename?: 'UserRole' } & ApiUserRoleFragment>;
};

export const UserFragmentDoc = gql`
  fragment User on User {
    __typename
    id
    kind
    loginPhone
    preferredPhone
    explicitPreferredPhone
    email
    name
    timezone
    transportKinds
    airtableDriverId
    createdAt
    onboardedAtDate
    hostAtSites {
      ...SiteBriefSummary
    }
    transportAtSites {
      ...SiteBriefSummary
    }
    primaryContactAtSites {
      ...SiteBriefSummary
    }
    organisation {
      ...OrganisationSummary
    }
    preferences {
      ...UserPreferences
    }
    roles {
      ...UserRole
    }
    features
    canResetPassword
    lastSeen
    status
    editable
  }
  ${SiteBriefSummaryFragmentDoc}
  ${OrganisationSummaryFragmentDoc}
  ${UserPreferencesFragmentDoc}
  ${UserRoleFragmentDoc}
`;
