import { Tooltip } from 'antd';
import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { LocationDescriptor } from 'history';
import { HashLink, HashLinkProps } from 'react-router-hash-link';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgDocument } from 'xo/svg/svg-document';
import { SvgTick } from 'xo/svg/svg-tick';
import { useCopyLink } from './copy-hooks';
import { FadeEnterExit } from './fade-enter-exit';

export interface CopyLinkProps extends Omit<HashLinkProps, 'to'> {
  to: LocationDescriptor;
  size?: 'sm' | 'md' | 'lg';
}

export const CopyLink = ({ to, size = 'lg', ...rest }: CopyLinkProps) => {
  const { onCopy, copied } = useCopyLink(to);

  const content = (
    <span
      className={classNames('text-blue-600 underline', {
        'text-sm': size === 'sm',
        'text-base': size === 'md',
        'text-lg': size === 'lg',
      })}
    >
      {rest.children}
    </span>
  );

  return (
    <div className="flex h-full items-center">
      <HashLink {...rest} to={to}>
        {content}
      </HashLink>
      <Tooltip visible={copied} title="Copied!" placement="top">
        <span className="ml-2">
          <AnimatePresence mode="wait">
            <FadeEnterExit key={String(copied)}>
              {!copied ? (
                <button
                  onClick={onCopy}
                  type="button"
                  className="flex items-center"
                >
                  <SvgDocument fill={colors.blue[600]} />
                </button>
              ) : (
                <SvgTick fill={colors.green[500]} />
              )}
            </FadeEnterExit>
          </AnimatePresence>
        </span>
      </Tooltip>
    </div>
  );
};
