export const fontFamily = {
  "sans": [
    "Inter"
  ]
};
export const fontSize = {
  "xs": "0.75rem",
  "sm": "0.875rem",
  "base": "1rem",
  "lg": "1.125rem",
  "xl": "1.25rem",
  "2xl": "1.5rem",
  "3xl": "1.875rem",
  "4xl": "2.25rem",
  "5xl": "3rem",
  "6xl": "4rem"
};
export const colors = {
  "transparent": "transparent",
  "current": "currentColor",
  "black": "#000",
  "white": "#fff",
  "brandOrange": {
    "400": "#F5B457",
    "500": "#F0920C"
  },
  "brandGrey": {
    "200": "#F9FAFB",
    "400": "#686D76",
    "600": "#373A40"
  },
  "grey": {
    "50": "#FAFAFA",
    "100": "#F5F5F5",
    "200": "#EEEEEE",
    "300": "#E0E0E0",
    "400": "#BDBDBD",
    "500": "#9E9E9E",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121"
  },
  "orange": {
    "50": "",
    "100": "#FFF0CF",
    "200": "#FEC854",
    "300": "#F2994A",
    "400": "#FD9827",
    "500": "#F0920C",
    "600": "#C4691A",
    "700": "#E65100",
    "800": "",
    "900": ""
  },
  "blue": {
    "50": "#E3F2FD",
    "100": "#BADEFE",
    "200": "#69B6FF",
    "300": "#6DB8FD",
    "340": "#42A5F5",
    "350": "#2196F3",
    "400": "#2589E2",
    "500": "#1565C0",
    "600": "#0C5EB0",
    "700": "#0a549e",
    "800": "",
    "900": ""
  },
  "red": {
    "50": "#FEF1F0",
    "100": "#FFEBEE",
    "200": "#FDC8C4",
    "300": "#FBA8A2",
    "400": "#EB5757",
    "500": "#E53935",
    "600": "#D81608",
    "700": "#B71C1C",
    "800": "#B00200",
    "900": "#760C04"
  },
  "green": {
    "50": "#E9FDE7",
    "100": "#DCEDC8",
    "200": "#bfde9a",
    "300": "#9dcd65",
    "400": "#19B00C",
    "500": "#43A047",
    "600": "#179E0B",
    "700": "#558B2F",
    "800": "#117708",
    "900": "#0C5106"
  },
  "purple": {
    "50": "#F3E5F5",
    "100": "",
    "200": "#CE93D8",
    "300": "#E1BEE7",
    "400": "",
    "500": "#9C27B0",
    "600": "",
    "700": "",
    "800": "",
    "900": ""
  },
  "teal": {
    "50": "#E0F2F1",
    "100": "",
    "200": "",
    "300": "#B3E0DB",
    "400": "#26A69A",
    "500": "#009688",
    "600": "#00897B",
    "700": "",
    "800": "",
    "900": ""
  },
  "indigo": {
    "300": "#7986CB"
  },
  "pink": {
    "200": "#F48FB1"
  }
};
export const screens = {
  "sm": "640px",
  "md": "768px",
  "lg": "1024px",
  "xl": "1280px",
  "2xl": "1536px",
  "xs": "321px",
  "print": {
    "raw": "print"
  }
};
export const supports = {};
export const columns = {
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "8": "8",
  "9": "9",
  "10": "10",
  "11": "11",
  "12": "12",
  "auto": "auto",
  "3xs": "16rem",
  "2xs": "18rem",
  "xs": "20rem",
  "sm": "24rem",
  "md": "28rem",
  "lg": "32rem",
  "xl": "36rem",
  "2xl": "42rem",
  "3xl": "48rem",
  "4xl": "56rem",
  "5xl": "64rem",
  "6xl": "72rem",
  "7xl": "80rem"
};
export const spacing = {
  "0": "0px",
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "11": "2.75rem",
  "12": "3rem",
  "14": "3.5rem",
  "16": "4rem",
  "20": "5rem",
  "24": "6rem",
  "28": "7rem",
  "32": "8rem",
  "36": "9rem",
  "40": "10rem",
  "44": "11rem",
  "48": "12rem",
  "52": "13rem",
  "56": "14rem",
  "60": "15rem",
  "64": "16rem",
  "72": "18rem",
  "80": "20rem",
  "96": "24rem",
  "px": "1px",
  "0.5": "0.125rem",
  "1.5": "0.375rem",
  "2.5": "0.625rem",
  "3.5": "0.875rem"
};
export const animation = {
  "none": "none",
  "spin": "spin 1s linear infinite",
  "ping": "ping 1s cubic-bezier(0, 0, 0.2, 1) infinite",
  "pulse": "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite",
  "bounce": "bounce 1s infinite"
};
export const aria = {
  "checked": "checked=\"true\"",
  "disabled": "disabled=\"true\"",
  "expanded": "expanded=\"true\"",
  "hidden": "hidden=\"true\"",
  "pressed": "pressed=\"true\"",
  "readonly": "readonly=\"true\"",
  "required": "required=\"true\"",
  "selected": "selected=\"true\""
};
export const aspectRatio = {
  "auto": "auto",
  "square": "1 / 1",
  "video": "16 / 9"
};
export const backdropBlur = {
  "0": "0",
  "none": "0",
  "sm": "4px",
  "DEFAULT": "8px",
  "md": "12px",
  "lg": "16px",
  "xl": "24px",
  "2xl": "40px",
  "3xl": "64px"
};
export const backdropBrightness = {
  "0": "0",
  "50": ".5",
  "75": ".75",
  "90": ".9",
  "95": ".95",
  "100": "1",
  "105": "1.05",
  "110": "1.1",
  "125": "1.25",
  "150": "1.5",
  "200": "2"
};
export const backdropContrast = {
  "0": "0",
  "50": ".5",
  "75": ".75",
  "100": "1",
  "125": "1.25",
  "150": "1.5",
  "200": "2"
};
export const backdropGrayscale = {
  "0": "0",
  "DEFAULT": "100%"
};
export const backdropHueRotate = {
  "0": "0deg",
  "15": "15deg",
  "30": "30deg",
  "60": "60deg",
  "90": "90deg",
  "180": "180deg"
};
export const backdropInvert = {
  "0": "0",
  "DEFAULT": "100%"
};
export const backdropOpacity = {
  "0": "0",
  "5": "0.05",
  "10": "0.1",
  "20": "0.2",
  "25": "0.25",
  "30": "0.3",
  "40": "0.4",
  "50": "0.5",
  "60": "0.6",
  "70": "0.7",
  "75": "0.75",
  "80": "0.8",
  "90": "0.9",
  "95": "0.95",
  "100": "1"
};
export const backdropSaturate = {
  "0": "0",
  "50": ".5",
  "100": "1",
  "150": "1.5",
  "200": "2"
};
export const backdropSepia = {
  "0": "0",
  "DEFAULT": "100%"
};
export const backgroundColor = {
  "transparent": "transparent",
  "current": "currentColor",
  "black": "#000",
  "white": "#fff",
  "brandOrange": {
    "400": "#F5B457",
    "500": "#F0920C"
  },
  "brandGrey": {
    "200": "#F9FAFB",
    "400": "#686D76",
    "600": "#373A40"
  },
  "grey": {
    "50": "#FAFAFA",
    "100": "#F5F5F5",
    "200": "#EEEEEE",
    "300": "#E0E0E0",
    "400": "#BDBDBD",
    "500": "#9E9E9E",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121"
  },
  "orange": {
    "50": "",
    "100": "#FFF0CF",
    "200": "#FEC854",
    "300": "#F2994A",
    "400": "#FD9827",
    "500": "#F0920C",
    "600": "#C4691A",
    "700": "#E65100",
    "800": "",
    "900": ""
  },
  "blue": {
    "50": "#E3F2FD",
    "100": "#BADEFE",
    "200": "#69B6FF",
    "300": "#6DB8FD",
    "340": "#42A5F5",
    "350": "#2196F3",
    "400": "#2589E2",
    "500": "#1565C0",
    "600": "#0C5EB0",
    "700": "#0a549e",
    "800": "",
    "900": ""
  },
  "red": {
    "50": "#FEF1F0",
    "100": "#FFEBEE",
    "200": "#FDC8C4",
    "300": "#FBA8A2",
    "400": "#EB5757",
    "500": "#E53935",
    "600": "#D81608",
    "700": "#B71C1C",
    "800": "#B00200",
    "900": "#760C04"
  },
  "green": {
    "50": "#E9FDE7",
    "100": "#DCEDC8",
    "200": "#bfde9a",
    "300": "#9dcd65",
    "400": "#19B00C",
    "500": "#43A047",
    "600": "#179E0B",
    "700": "#558B2F",
    "800": "#117708",
    "900": "#0C5106"
  },
  "purple": {
    "50": "#F3E5F5",
    "100": "",
    "200": "#CE93D8",
    "300": "#E1BEE7",
    "400": "",
    "500": "#9C27B0",
    "600": "",
    "700": "",
    "800": "",
    "900": ""
  },
  "teal": {
    "50": "#E0F2F1",
    "100": "",
    "200": "",
    "300": "#B3E0DB",
    "400": "#26A69A",
    "500": "#009688",
    "600": "#00897B",
    "700": "",
    "800": "",
    "900": ""
  },
  "indigo": {
    "300": "#7986CB"
  },
  "pink": {
    "200": "#F48FB1"
  }
};
export const backgroundImage = {
  "none": "none",
  "gradient-to-t": "linear-gradient(to top, var(--tw-gradient-stops))",
  "gradient-to-tr": "linear-gradient(to top right, var(--tw-gradient-stops))",
  "gradient-to-r": "linear-gradient(to right, var(--tw-gradient-stops))",
  "gradient-to-br": "linear-gradient(to bottom right, var(--tw-gradient-stops))",
  "gradient-to-b": "linear-gradient(to bottom, var(--tw-gradient-stops))",
  "gradient-to-bl": "linear-gradient(to bottom left, var(--tw-gradient-stops))",
  "gradient-to-l": "linear-gradient(to left, var(--tw-gradient-stops))",
  "gradient-to-tl": "linear-gradient(to top left, var(--tw-gradient-stops))"
};
export const backgroundOpacity = {
  "0": "0",
  "5": "0.05",
  "10": "0.1",
  "20": "0.2",
  "25": "0.25",
  "30": "0.3",
  "40": "0.4",
  "50": "0.5",
  "60": "0.6",
  "70": "0.7",
  "75": "0.75",
  "80": "0.8",
  "90": "0.9",
  "95": "0.95",
  "100": "1"
};
export const backgroundPosition = {
  "bottom": "bottom",
  "center": "center",
  "left": "left",
  "left-bottom": "left bottom",
  "left-top": "left top",
  "right": "right",
  "right-bottom": "right bottom",
  "right-top": "right top",
  "top": "top"
};
export const backgroundSize = {
  "auto": "auto",
  "cover": "cover",
  "contain": "contain"
};
export const blur = {
  "0": "0",
  "none": "0",
  "sm": "4px",
  "DEFAULT": "8px",
  "md": "12px",
  "lg": "16px",
  "xl": "24px",
  "2xl": "40px",
  "3xl": "64px"
};
export const brightness = {
  "0": "0",
  "50": ".5",
  "75": ".75",
  "90": ".9",
  "95": ".95",
  "100": "1",
  "105": "1.05",
  "110": "1.1",
  "125": "1.25",
  "150": "1.5",
  "200": "2"
};
export const borderColor = {
  "transparent": "transparent",
  "current": "currentColor",
  "black": "#000",
  "white": "#fff",
  "brandOrange": {
    "400": "#F5B457",
    "500": "#F0920C"
  },
  "brandGrey": {
    "200": "#F9FAFB",
    "400": "#686D76",
    "600": "#373A40"
  },
  "grey": {
    "50": "#FAFAFA",
    "100": "#F5F5F5",
    "200": "#EEEEEE",
    "300": "#E0E0E0",
    "400": "#BDBDBD",
    "500": "#9E9E9E",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121"
  },
  "orange": {
    "50": "",
    "100": "#FFF0CF",
    "200": "#FEC854",
    "300": "#F2994A",
    "400": "#FD9827",
    "500": "#F0920C",
    "600": "#C4691A",
    "700": "#E65100",
    "800": "",
    "900": ""
  },
  "blue": {
    "50": "#E3F2FD",
    "100": "#BADEFE",
    "200": "#69B6FF",
    "300": "#6DB8FD",
    "340": "#42A5F5",
    "350": "#2196F3",
    "400": "#2589E2",
    "500": "#1565C0",
    "600": "#0C5EB0",
    "700": "#0a549e",
    "800": "",
    "900": ""
  },
  "red": {
    "50": "#FEF1F0",
    "100": "#FFEBEE",
    "200": "#FDC8C4",
    "300": "#FBA8A2",
    "400": "#EB5757",
    "500": "#E53935",
    "600": "#D81608",
    "700": "#B71C1C",
    "800": "#B00200",
    "900": "#760C04"
  },
  "green": {
    "50": "#E9FDE7",
    "100": "#DCEDC8",
    "200": "#bfde9a",
    "300": "#9dcd65",
    "400": "#19B00C",
    "500": "#43A047",
    "600": "#179E0B",
    "700": "#558B2F",
    "800": "#117708",
    "900": "#0C5106"
  },
  "purple": {
    "50": "#F3E5F5",
    "100": "",
    "200": "#CE93D8",
    "300": "#E1BEE7",
    "400": "",
    "500": "#9C27B0",
    "600": "",
    "700": "",
    "800": "",
    "900": ""
  },
  "teal": {
    "50": "#E0F2F1",
    "100": "",
    "200": "",
    "300": "#B3E0DB",
    "400": "#26A69A",
    "500": "#009688",
    "600": "#00897B",
    "700": "",
    "800": "",
    "900": ""
  },
  "indigo": {
    "300": "#7986CB"
  },
  "pink": {
    "200": "#F48FB1"
  },
  "DEFAULT": "currentColor"
};
export const borderOpacity = {
  "0": "0",
  "5": "0.05",
  "10": "0.1",
  "20": "0.2",
  "25": "0.25",
  "30": "0.3",
  "40": "0.4",
  "50": "0.5",
  "60": "0.6",
  "70": "0.7",
  "75": "0.75",
  "80": "0.8",
  "90": "0.9",
  "95": "0.95",
  "100": "1"
};
export const borderRadius = {
  "none": "0px",
  "sm": "0.125rem",
  "DEFAULT": "0.25rem",
  "md": "0.375rem",
  "lg": "0.5rem",
  "xl": "0.75rem",
  "2xl": "1rem",
  "3xl": "1.5rem",
  "full": "9999px"
};
export const borderSpacing = {
  "0": "0px",
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "11": "2.75rem",
  "12": "3rem",
  "14": "3.5rem",
  "16": "4rem",
  "20": "5rem",
  "24": "6rem",
  "28": "7rem",
  "32": "8rem",
  "36": "9rem",
  "40": "10rem",
  "44": "11rem",
  "48": "12rem",
  "52": "13rem",
  "56": "14rem",
  "60": "15rem",
  "64": "16rem",
  "72": "18rem",
  "80": "20rem",
  "96": "24rem",
  "px": "1px",
  "0.5": "0.125rem",
  "1.5": "0.375rem",
  "2.5": "0.625rem",
  "3.5": "0.875rem"
};
export const borderWidth = {
  "0": "0px",
  "2": "2px",
  "3": "3px",
  "4": "4px",
  "8": "8px",
  "DEFAULT": "1px"
};
export const boxShadow = {
  "sm": "0 1px 2px 0 rgb(0 0 0 / 0.05)",
  "DEFAULT": "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
  "md": "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
  "lg": "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
  "xl": "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
  "2xl": "0 25px 50px -12px rgb(0 0 0 / 0.25)",
  "inner": "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
  "none": "none",
  "above": "0px 7px 15px 5px rgba(0,0,0,0.7)",
  "r-lg": "10px 0 15px -3px rgba(0, 0, 0, 0.1), 4px 0 6px -2px rgba(0, 0, 0, 0.05);",
  "t-lg": "0 -10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px 2px rgb(0 0 0 / 0.05)",
  "t-md": "0 -4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -1px rgb(0 0 0 / 0.06)",
  "bold": "0px 2px 5px 0px rgba(0,0,0,0.5)",
  "semi-bold": "0px 1px 3px 0px rgba(0,0,0,0.5)",
  "b-sm": "0px 5px 5px 0px rgba(0,0,0,0.1);",
  "t-sm": "0px -5px 5px 0px rgba(0,0,0,0.1);",
  "r-sm": "5px 0px 5px 0px rgba(0,0,0,0.1);",
  "l-sm": "-5px 0px 5px 0px rgba(0,0,0,0.1);"
};
export const boxShadowColor = {
  "transparent": "transparent",
  "current": "currentColor",
  "black": "#000",
  "white": "#fff",
  "brandOrange": {
    "400": "#F5B457",
    "500": "#F0920C"
  },
  "brandGrey": {
    "200": "#F9FAFB",
    "400": "#686D76",
    "600": "#373A40"
  },
  "grey": {
    "50": "#FAFAFA",
    "100": "#F5F5F5",
    "200": "#EEEEEE",
    "300": "#E0E0E0",
    "400": "#BDBDBD",
    "500": "#9E9E9E",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121"
  },
  "orange": {
    "50": "",
    "100": "#FFF0CF",
    "200": "#FEC854",
    "300": "#F2994A",
    "400": "#FD9827",
    "500": "#F0920C",
    "600": "#C4691A",
    "700": "#E65100",
    "800": "",
    "900": ""
  },
  "blue": {
    "50": "#E3F2FD",
    "100": "#BADEFE",
    "200": "#69B6FF",
    "300": "#6DB8FD",
    "340": "#42A5F5",
    "350": "#2196F3",
    "400": "#2589E2",
    "500": "#1565C0",
    "600": "#0C5EB0",
    "700": "#0a549e",
    "800": "",
    "900": ""
  },
  "red": {
    "50": "#FEF1F0",
    "100": "#FFEBEE",
    "200": "#FDC8C4",
    "300": "#FBA8A2",
    "400": "#EB5757",
    "500": "#E53935",
    "600": "#D81608",
    "700": "#B71C1C",
    "800": "#B00200",
    "900": "#760C04"
  },
  "green": {
    "50": "#E9FDE7",
    "100": "#DCEDC8",
    "200": "#bfde9a",
    "300": "#9dcd65",
    "400": "#19B00C",
    "500": "#43A047",
    "600": "#179E0B",
    "700": "#558B2F",
    "800": "#117708",
    "900": "#0C5106"
  },
  "purple": {
    "50": "#F3E5F5",
    "100": "",
    "200": "#CE93D8",
    "300": "#E1BEE7",
    "400": "",
    "500": "#9C27B0",
    "600": "",
    "700": "",
    "800": "",
    "900": ""
  },
  "teal": {
    "50": "#E0F2F1",
    "100": "",
    "200": "",
    "300": "#B3E0DB",
    "400": "#26A69A",
    "500": "#009688",
    "600": "#00897B",
    "700": "",
    "800": "",
    "900": ""
  },
  "indigo": {
    "300": "#7986CB"
  },
  "pink": {
    "200": "#F48FB1"
  }
};
export const caretColor = {
  "transparent": "transparent",
  "current": "currentColor",
  "black": "#000",
  "white": "#fff",
  "brandOrange": {
    "400": "#F5B457",
    "500": "#F0920C"
  },
  "brandGrey": {
    "200": "#F9FAFB",
    "400": "#686D76",
    "600": "#373A40"
  },
  "grey": {
    "50": "#FAFAFA",
    "100": "#F5F5F5",
    "200": "#EEEEEE",
    "300": "#E0E0E0",
    "400": "#BDBDBD",
    "500": "#9E9E9E",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121"
  },
  "orange": {
    "50": "",
    "100": "#FFF0CF",
    "200": "#FEC854",
    "300": "#F2994A",
    "400": "#FD9827",
    "500": "#F0920C",
    "600": "#C4691A",
    "700": "#E65100",
    "800": "",
    "900": ""
  },
  "blue": {
    "50": "#E3F2FD",
    "100": "#BADEFE",
    "200": "#69B6FF",
    "300": "#6DB8FD",
    "340": "#42A5F5",
    "350": "#2196F3",
    "400": "#2589E2",
    "500": "#1565C0",
    "600": "#0C5EB0",
    "700": "#0a549e",
    "800": "",
    "900": ""
  },
  "red": {
    "50": "#FEF1F0",
    "100": "#FFEBEE",
    "200": "#FDC8C4",
    "300": "#FBA8A2",
    "400": "#EB5757",
    "500": "#E53935",
    "600": "#D81608",
    "700": "#B71C1C",
    "800": "#B00200",
    "900": "#760C04"
  },
  "green": {
    "50": "#E9FDE7",
    "100": "#DCEDC8",
    "200": "#bfde9a",
    "300": "#9dcd65",
    "400": "#19B00C",
    "500": "#43A047",
    "600": "#179E0B",
    "700": "#558B2F",
    "800": "#117708",
    "900": "#0C5106"
  },
  "purple": {
    "50": "#F3E5F5",
    "100": "",
    "200": "#CE93D8",
    "300": "#E1BEE7",
    "400": "",
    "500": "#9C27B0",
    "600": "",
    "700": "",
    "800": "",
    "900": ""
  },
  "teal": {
    "50": "#E0F2F1",
    "100": "",
    "200": "",
    "300": "#B3E0DB",
    "400": "#26A69A",
    "500": "#009688",
    "600": "#00897B",
    "700": "",
    "800": "",
    "900": ""
  },
  "indigo": {
    "300": "#7986CB"
  },
  "pink": {
    "200": "#F48FB1"
  }
};
export const accentColor = {
  "transparent": "transparent",
  "current": "currentColor",
  "black": "#000",
  "white": "#fff",
  "brandOrange": {
    "400": "#F5B457",
    "500": "#F0920C"
  },
  "brandGrey": {
    "200": "#F9FAFB",
    "400": "#686D76",
    "600": "#373A40"
  },
  "grey": {
    "50": "#FAFAFA",
    "100": "#F5F5F5",
    "200": "#EEEEEE",
    "300": "#E0E0E0",
    "400": "#BDBDBD",
    "500": "#9E9E9E",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121"
  },
  "orange": {
    "50": "",
    "100": "#FFF0CF",
    "200": "#FEC854",
    "300": "#F2994A",
    "400": "#FD9827",
    "500": "#F0920C",
    "600": "#C4691A",
    "700": "#E65100",
    "800": "",
    "900": ""
  },
  "blue": {
    "50": "#E3F2FD",
    "100": "#BADEFE",
    "200": "#69B6FF",
    "300": "#6DB8FD",
    "340": "#42A5F5",
    "350": "#2196F3",
    "400": "#2589E2",
    "500": "#1565C0",
    "600": "#0C5EB0",
    "700": "#0a549e",
    "800": "",
    "900": ""
  },
  "red": {
    "50": "#FEF1F0",
    "100": "#FFEBEE",
    "200": "#FDC8C4",
    "300": "#FBA8A2",
    "400": "#EB5757",
    "500": "#E53935",
    "600": "#D81608",
    "700": "#B71C1C",
    "800": "#B00200",
    "900": "#760C04"
  },
  "green": {
    "50": "#E9FDE7",
    "100": "#DCEDC8",
    "200": "#bfde9a",
    "300": "#9dcd65",
    "400": "#19B00C",
    "500": "#43A047",
    "600": "#179E0B",
    "700": "#558B2F",
    "800": "#117708",
    "900": "#0C5106"
  },
  "purple": {
    "50": "#F3E5F5",
    "100": "",
    "200": "#CE93D8",
    "300": "#E1BEE7",
    "400": "",
    "500": "#9C27B0",
    "600": "",
    "700": "",
    "800": "",
    "900": ""
  },
  "teal": {
    "50": "#E0F2F1",
    "100": "",
    "200": "",
    "300": "#B3E0DB",
    "400": "#26A69A",
    "500": "#009688",
    "600": "#00897B",
    "700": "",
    "800": "",
    "900": ""
  },
  "indigo": {
    "300": "#7986CB"
  },
  "pink": {
    "200": "#F48FB1"
  },
  "auto": "auto"
};
export const contrast = {
  "0": "0",
  "50": ".5",
  "75": ".75",
  "100": "1",
  "125": "1.25",
  "150": "1.5",
  "200": "2"
};
export const container = {};
export const content = {
  "none": "none"
};
export const cursor = {
  "auto": "auto",
  "default": "default",
  "pointer": "pointer",
  "wait": "wait",
  "text": "text",
  "move": "move",
  "help": "help",
  "not-allowed": "not-allowed",
  "none": "none",
  "context-menu": "context-menu",
  "progress": "progress",
  "cell": "cell",
  "crosshair": "crosshair",
  "vertical-text": "vertical-text",
  "alias": "alias",
  "copy": "copy",
  "no-drop": "no-drop",
  "grab": "grab",
  "grabbing": "grabbing",
  "all-scroll": "all-scroll",
  "col-resize": "col-resize",
  "row-resize": "row-resize",
  "n-resize": "n-resize",
  "e-resize": "e-resize",
  "s-resize": "s-resize",
  "w-resize": "w-resize",
  "ne-resize": "ne-resize",
  "nw-resize": "nw-resize",
  "se-resize": "se-resize",
  "sw-resize": "sw-resize",
  "ew-resize": "ew-resize",
  "ns-resize": "ns-resize",
  "nesw-resize": "nesw-resize",
  "nwse-resize": "nwse-resize",
  "zoom-in": "zoom-in",
  "zoom-out": "zoom-out"
};
export const divideColor = {
  "transparent": "transparent",
  "current": "currentColor",
  "black": "#000",
  "white": "#fff",
  "brandOrange": {
    "400": "#F5B457",
    "500": "#F0920C"
  },
  "brandGrey": {
    "200": "#F9FAFB",
    "400": "#686D76",
    "600": "#373A40"
  },
  "grey": {
    "50": "#FAFAFA",
    "100": "#F5F5F5",
    "200": "#EEEEEE",
    "300": "#E0E0E0",
    "400": "#BDBDBD",
    "500": "#9E9E9E",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121"
  },
  "orange": {
    "50": "",
    "100": "#FFF0CF",
    "200": "#FEC854",
    "300": "#F2994A",
    "400": "#FD9827",
    "500": "#F0920C",
    "600": "#C4691A",
    "700": "#E65100",
    "800": "",
    "900": ""
  },
  "blue": {
    "50": "#E3F2FD",
    "100": "#BADEFE",
    "200": "#69B6FF",
    "300": "#6DB8FD",
    "340": "#42A5F5",
    "350": "#2196F3",
    "400": "#2589E2",
    "500": "#1565C0",
    "600": "#0C5EB0",
    "700": "#0a549e",
    "800": "",
    "900": ""
  },
  "red": {
    "50": "#FEF1F0",
    "100": "#FFEBEE",
    "200": "#FDC8C4",
    "300": "#FBA8A2",
    "400": "#EB5757",
    "500": "#E53935",
    "600": "#D81608",
    "700": "#B71C1C",
    "800": "#B00200",
    "900": "#760C04"
  },
  "green": {
    "50": "#E9FDE7",
    "100": "#DCEDC8",
    "200": "#bfde9a",
    "300": "#9dcd65",
    "400": "#19B00C",
    "500": "#43A047",
    "600": "#179E0B",
    "700": "#558B2F",
    "800": "#117708",
    "900": "#0C5106"
  },
  "purple": {
    "50": "#F3E5F5",
    "100": "",
    "200": "#CE93D8",
    "300": "#E1BEE7",
    "400": "",
    "500": "#9C27B0",
    "600": "",
    "700": "",
    "800": "",
    "900": ""
  },
  "teal": {
    "50": "#E0F2F1",
    "100": "",
    "200": "",
    "300": "#B3E0DB",
    "400": "#26A69A",
    "500": "#009688",
    "600": "#00897B",
    "700": "",
    "800": "",
    "900": ""
  },
  "indigo": {
    "300": "#7986CB"
  },
  "pink": {
    "200": "#F48FB1"
  },
  "DEFAULT": "currentColor"
};
export const divideOpacity = {
  "0": "0",
  "5": "0.05",
  "10": "0.1",
  "20": "0.2",
  "25": "0.25",
  "30": "0.3",
  "40": "0.4",
  "50": "0.5",
  "60": "0.6",
  "70": "0.7",
  "75": "0.75",
  "80": "0.8",
  "90": "0.9",
  "95": "0.95",
  "100": "1"
};
export const divideWidth = {
  "0": "0px",
  "2": "2px",
  "3": "3px",
  "4": "4px",
  "8": "8px",
  "DEFAULT": "1px"
};
export const dropShadow = {
  "sm": "0 1px 1px rgb(0 0 0 / 0.05)",
  "DEFAULT": [
    "0 1px 2px rgb(0 0 0 / 0.1)",
    "0 1px 1px rgb(0 0 0 / 0.06)"
  ],
  "md": [
    "0 4px 3px rgb(0 0 0 / 0.07)",
    "0 2px 2px rgb(0 0 0 / 0.06)"
  ],
  "lg": [
    "0 10px 8px rgb(0 0 0 / 0.04)",
    "0 4px 3px rgb(0 0 0 / 0.1)"
  ],
  "xl": [
    "0 20px 13px rgb(0 0 0 / 0.03)",
    "0 8px 5px rgb(0 0 0 / 0.08)"
  ],
  "2xl": "0 25px 25px rgb(0 0 0 / 0.15)",
  "none": "0 0 #0000"
};
export const fill = {
  "none": "none",
  "transparent": "transparent",
  "current": "currentColor",
  "black": "#000",
  "white": "#fff",
  "brandOrange": {
    "400": "#F5B457",
    "500": "#F0920C"
  },
  "brandGrey": {
    "200": "#F9FAFB",
    "400": "#686D76",
    "600": "#373A40"
  },
  "grey": {
    "50": "#FAFAFA",
    "100": "#F5F5F5",
    "200": "#EEEEEE",
    "300": "#E0E0E0",
    "400": "#BDBDBD",
    "500": "#9E9E9E",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121"
  },
  "orange": {
    "50": "",
    "100": "#FFF0CF",
    "200": "#FEC854",
    "300": "#F2994A",
    "400": "#FD9827",
    "500": "#F0920C",
    "600": "#C4691A",
    "700": "#E65100",
    "800": "",
    "900": ""
  },
  "blue": {
    "50": "#E3F2FD",
    "100": "#BADEFE",
    "200": "#69B6FF",
    "300": "#6DB8FD",
    "340": "#42A5F5",
    "350": "#2196F3",
    "400": "#2589E2",
    "500": "#1565C0",
    "600": "#0C5EB0",
    "700": "#0a549e",
    "800": "",
    "900": ""
  },
  "red": {
    "50": "#FEF1F0",
    "100": "#FFEBEE",
    "200": "#FDC8C4",
    "300": "#FBA8A2",
    "400": "#EB5757",
    "500": "#E53935",
    "600": "#D81608",
    "700": "#B71C1C",
    "800": "#B00200",
    "900": "#760C04"
  },
  "green": {
    "50": "#E9FDE7",
    "100": "#DCEDC8",
    "200": "#bfde9a",
    "300": "#9dcd65",
    "400": "#19B00C",
    "500": "#43A047",
    "600": "#179E0B",
    "700": "#558B2F",
    "800": "#117708",
    "900": "#0C5106"
  },
  "purple": {
    "50": "#F3E5F5",
    "100": "",
    "200": "#CE93D8",
    "300": "#E1BEE7",
    "400": "",
    "500": "#9C27B0",
    "600": "",
    "700": "",
    "800": "",
    "900": ""
  },
  "teal": {
    "50": "#E0F2F1",
    "100": "",
    "200": "",
    "300": "#B3E0DB",
    "400": "#26A69A",
    "500": "#009688",
    "600": "#00897B",
    "700": "",
    "800": "",
    "900": ""
  },
  "indigo": {
    "300": "#7986CB"
  },
  "pink": {
    "200": "#F48FB1"
  }
};
export const grayscale = {
  "0": "0",
  "DEFAULT": "100%"
};
export const hueRotate = {
  "0": "0deg",
  "15": "15deg",
  "30": "30deg",
  "60": "60deg",
  "90": "90deg",
  "180": "180deg"
};
export const invert = {
  "0": "0",
  "DEFAULT": "100%"
};
export const flex = {
  "1": "1 1 0%",
  "auto": "1 1 auto",
  "initial": "0 1 auto",
  "none": "none"
};
export const flexBasis = {
  "0": "0px",
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "11": "2.75rem",
  "12": "3rem",
  "14": "3.5rem",
  "16": "4rem",
  "20": "5rem",
  "24": "6rem",
  "28": "7rem",
  "32": "8rem",
  "36": "9rem",
  "40": "10rem",
  "44": "11rem",
  "48": "12rem",
  "52": "13rem",
  "56": "14rem",
  "60": "15rem",
  "64": "16rem",
  "72": "18rem",
  "80": "20rem",
  "96": "24rem",
  "auto": "auto",
  "px": "1px",
  "0.5": "0.125rem",
  "1.5": "0.375rem",
  "2.5": "0.625rem",
  "3.5": "0.875rem",
  "1/2": "50%",
  "1/3": "33.333333%",
  "2/3": "66.666667%",
  "1/4": "25%",
  "2/4": "50%",
  "3/4": "75%",
  "1/5": "20%",
  "2/5": "40%",
  "3/5": "60%",
  "4/5": "80%",
  "1/6": "16.666667%",
  "2/6": "33.333333%",
  "3/6": "50%",
  "4/6": "66.666667%",
  "5/6": "83.333333%",
  "1/12": "8.333333%",
  "2/12": "16.666667%",
  "3/12": "25%",
  "4/12": "33.333333%",
  "5/12": "41.666667%",
  "6/12": "50%",
  "7/12": "58.333333%",
  "8/12": "66.666667%",
  "9/12": "75%",
  "10/12": "83.333333%",
  "11/12": "91.666667%",
  "full": "100%"
};
export const flexGrow = {
  "0": "0",
  "DEFAULT": "1"
};
export const flexShrink = {
  "0": "0",
  "DEFAULT": "1"
};
export const fontWeight = {
  "thin": "100",
  "extralight": "200",
  "light": "300",
  "normal": "400",
  "medium": "500",
  "semibold": "600",
  "bold": "700",
  "extrabold": "800",
  "black": "900"
};
export const gap = {
  "0": "0px",
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "11": "2.75rem",
  "12": "3rem",
  "14": "3.5rem",
  "16": "4rem",
  "20": "5rem",
  "24": "6rem",
  "28": "7rem",
  "32": "8rem",
  "36": "9rem",
  "40": "10rem",
  "44": "11rem",
  "48": "12rem",
  "52": "13rem",
  "56": "14rem",
  "60": "15rem",
  "64": "16rem",
  "72": "18rem",
  "80": "20rem",
  "96": "24rem",
  "px": "1px",
  "0.5": "0.125rem",
  "1.5": "0.375rem",
  "2.5": "0.625rem",
  "3.5": "0.875rem"
};
export const gradientColorStops = {
  "transparent": "transparent",
  "current": "currentColor",
  "black": "#000",
  "white": "#fff",
  "brandOrange": {
    "400": "#F5B457",
    "500": "#F0920C"
  },
  "brandGrey": {
    "200": "#F9FAFB",
    "400": "#686D76",
    "600": "#373A40"
  },
  "grey": {
    "50": "#FAFAFA",
    "100": "#F5F5F5",
    "200": "#EEEEEE",
    "300": "#E0E0E0",
    "400": "#BDBDBD",
    "500": "#9E9E9E",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121"
  },
  "orange": {
    "50": "",
    "100": "#FFF0CF",
    "200": "#FEC854",
    "300": "#F2994A",
    "400": "#FD9827",
    "500": "#F0920C",
    "600": "#C4691A",
    "700": "#E65100",
    "800": "",
    "900": ""
  },
  "blue": {
    "50": "#E3F2FD",
    "100": "#BADEFE",
    "200": "#69B6FF",
    "300": "#6DB8FD",
    "340": "#42A5F5",
    "350": "#2196F3",
    "400": "#2589E2",
    "500": "#1565C0",
    "600": "#0C5EB0",
    "700": "#0a549e",
    "800": "",
    "900": ""
  },
  "red": {
    "50": "#FEF1F0",
    "100": "#FFEBEE",
    "200": "#FDC8C4",
    "300": "#FBA8A2",
    "400": "#EB5757",
    "500": "#E53935",
    "600": "#D81608",
    "700": "#B71C1C",
    "800": "#B00200",
    "900": "#760C04"
  },
  "green": {
    "50": "#E9FDE7",
    "100": "#DCEDC8",
    "200": "#bfde9a",
    "300": "#9dcd65",
    "400": "#19B00C",
    "500": "#43A047",
    "600": "#179E0B",
    "700": "#558B2F",
    "800": "#117708",
    "900": "#0C5106"
  },
  "purple": {
    "50": "#F3E5F5",
    "100": "",
    "200": "#CE93D8",
    "300": "#E1BEE7",
    "400": "",
    "500": "#9C27B0",
    "600": "",
    "700": "",
    "800": "",
    "900": ""
  },
  "teal": {
    "50": "#E0F2F1",
    "100": "",
    "200": "",
    "300": "#B3E0DB",
    "400": "#26A69A",
    "500": "#009688",
    "600": "#00897B",
    "700": "",
    "800": "",
    "900": ""
  },
  "indigo": {
    "300": "#7986CB"
  },
  "pink": {
    "200": "#F48FB1"
  }
};
export const gridAutoColumns = {
  "auto": "auto",
  "min": "min-content",
  "max": "max-content",
  "fr": "minmax(0, 1fr)"
};
export const gridAutoRows = {
  "auto": "auto",
  "min": "min-content",
  "max": "max-content",
  "fr": "minmax(0, 1fr)"
};
export const gridColumn = {
  "auto": "auto",
  "span-1": "span 1 / span 1",
  "span-2": "span 2 / span 2",
  "span-3": "span 3 / span 3",
  "span-4": "span 4 / span 4",
  "span-5": "span 5 / span 5",
  "span-6": "span 6 / span 6",
  "span-7": "span 7 / span 7",
  "span-8": "span 8 / span 8",
  "span-9": "span 9 / span 9",
  "span-10": "span 10 / span 10",
  "span-11": "span 11 / span 11",
  "span-12": "span 12 / span 12",
  "span-full": "1 / -1"
};
export const gridColumnEnd = {
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "8": "8",
  "9": "9",
  "10": "10",
  "11": "11",
  "12": "12",
  "13": "13",
  "auto": "auto"
};
export const gridColumnStart = {
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "8": "8",
  "9": "9",
  "10": "10",
  "11": "11",
  "12": "12",
  "13": "13",
  "auto": "auto"
};
export const gridRow = {
  "auto": "auto",
  "span-1": "span 1 / span 1",
  "span-2": "span 2 / span 2",
  "span-3": "span 3 / span 3",
  "span-4": "span 4 / span 4",
  "span-5": "span 5 / span 5",
  "span-6": "span 6 / span 6",
  "span-full": "1 / -1"
};
export const gridRowStart = {
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "auto": "auto"
};
export const gridRowEnd = {
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "auto": "auto"
};
export const gridTemplateColumns = {
  "1": "repeat(1, minmax(0, 1fr))",
  "2": "repeat(2, minmax(0, 1fr))",
  "3": "repeat(3, minmax(0, 1fr))",
  "4": "repeat(4, minmax(0, 1fr))",
  "5": "repeat(5, minmax(0, 1fr))",
  "6": "repeat(6, minmax(0, 1fr))",
  "7": "repeat(7, minmax(0, 1fr))",
  "8": "repeat(8, minmax(0, 1fr))",
  "9": "repeat(9, minmax(0, 1fr))",
  "10": "repeat(10, minmax(0, 1fr))",
  "11": "repeat(11, minmax(0, 1fr))",
  "12": "repeat(12, minmax(0, 1fr))",
  "none": "none"
};
export const gridTemplateRows = {
  "1": "repeat(1, minmax(0, 1fr))",
  "2": "repeat(2, minmax(0, 1fr))",
  "3": "repeat(3, minmax(0, 1fr))",
  "4": "repeat(4, minmax(0, 1fr))",
  "5": "repeat(5, minmax(0, 1fr))",
  "6": "repeat(6, minmax(0, 1fr))",
  "none": "none"
};
export const height = {
  "0": "0px",
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "11": "2.75rem",
  "12": "3rem",
  "14": "3.5rem",
  "16": "4rem",
  "20": "5rem",
  "24": "6rem",
  "28": "7rem",
  "32": "8rem",
  "36": "9rem",
  "40": "10rem",
  "44": "11rem",
  "48": "12rem",
  "52": "13rem",
  "56": "14rem",
  "60": "15rem",
  "64": "16rem",
  "72": "18rem",
  "80": "20rem",
  "96": "24rem",
  "auto": "auto",
  "px": "1px",
  "0.5": "0.125rem",
  "1.5": "0.375rem",
  "2.5": "0.625rem",
  "3.5": "0.875rem",
  "1/2": "50%",
  "1/3": "33.333333%",
  "2/3": "66.666667%",
  "1/4": "25%",
  "2/4": "50%",
  "3/4": "75%",
  "1/5": "20%",
  "2/5": "40%",
  "3/5": "60%",
  "4/5": "80%",
  "1/6": "16.666667%",
  "2/6": "33.333333%",
  "3/6": "50%",
  "4/6": "66.666667%",
  "5/6": "83.333333%",
  "full": "100%",
  "screen": "100vh",
  "min": "min-content",
  "max": "max-content",
  "fit": "fit-content"
};
export const inset = {
  "0": "0px",
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "11": "2.75rem",
  "12": "3rem",
  "14": "3.5rem",
  "16": "4rem",
  "20": "5rem",
  "24": "6rem",
  "28": "7rem",
  "32": "8rem",
  "36": "9rem",
  "40": "10rem",
  "44": "11rem",
  "48": "12rem",
  "52": "13rem",
  "56": "14rem",
  "60": "15rem",
  "64": "16rem",
  "72": "18rem",
  "80": "20rem",
  "96": "24rem",
  "auto": "auto",
  "px": "1px",
  "0.5": "0.125rem",
  "1.5": "0.375rem",
  "2.5": "0.625rem",
  "3.5": "0.875rem",
  "1/2": "50%",
  "1/3": "33.333333%",
  "2/3": "66.666667%",
  "1/4": "25%",
  "2/4": "50%",
  "3/4": "75%",
  "full": "100%"
};
export const keyframes = {
  "spin": {
    "to": {
      "transform": "rotate(360deg)"
    }
  },
  "ping": {
    "75%, 100%": {
      "transform": "scale(2)",
      "opacity": "0"
    }
  },
  "pulse": {
    "50%": {
      "opacity": ".5"
    }
  },
  "bounce": {
    "0%, 100%": {
      "transform": "translateY(-25%)",
      "animationTimingFunction": "cubic-bezier(0.8,0,1,1)"
    },
    "50%": {
      "transform": "none",
      "animationTimingFunction": "cubic-bezier(0,0,0.2,1)"
    }
  }
};
export const letterSpacing = {
  "tighter": "-0.05em",
  "tight": "-0.025em",
  "normal": "0em",
  "wide": "0.025em",
  "wider": "0.05em",
  "widest": "0.1em",
  "0.25px": "0.25px",
  "1px": "1px"
};
export const lineHeight = {
  "3": ".75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "none": "1",
  "tight": "1.25",
  "snug": "1.375",
  "normal": "1.5",
  "relaxed": "1.625",
  "loose": "2"
};
export const listStyleType = {
  "none": "none",
  "disc": "disc",
  "decimal": "decimal"
};
export const margin = {
  "0": "0px",
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "11": "2.75rem",
  "12": "3rem",
  "14": "3.5rem",
  "16": "4rem",
  "20": "5rem",
  "24": "6rem",
  "28": "7rem",
  "32": "8rem",
  "36": "9rem",
  "40": "10rem",
  "44": "11rem",
  "48": "12rem",
  "52": "13rem",
  "56": "14rem",
  "60": "15rem",
  "64": "16rem",
  "72": "18rem",
  "80": "20rem",
  "96": "24rem",
  "auto": "auto",
  "px": "1px",
  "0.5": "0.125rem",
  "1.5": "0.375rem",
  "2.5": "0.625rem",
  "3.5": "0.875rem"
};
export const maxHeight = {
  "0": "0px",
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "11": "2.75rem",
  "12": "3rem",
  "14": "3.5rem",
  "16": "4rem",
  "20": "5rem",
  "24": "6rem",
  "28": "7rem",
  "32": "8rem",
  "36": "9rem",
  "40": "10rem",
  "44": "11rem",
  "48": "12rem",
  "52": "13rem",
  "56": "14rem",
  "60": "15rem",
  "64": "16rem",
  "72": "18rem",
  "80": "20rem",
  "96": "24rem",
  "px": "1px",
  "0.5": "0.125rem",
  "1.5": "0.375rem",
  "2.5": "0.625rem",
  "3.5": "0.875rem",
  "full": "100%",
  "screen": "100vh",
  "min": "min-content",
  "max": "max-content",
  "fit": "fit-content"
};
export const maxWidth = {
  "0": "0rem",
  "32": "8rem",
  "64": "16rem",
  "96": "24rem",
  "none": "none",
  "xs": "20rem",
  "sm": "24rem",
  "md": "28rem",
  "lg": "32rem",
  "xl": "36rem",
  "2xl": "42rem",
  "3xl": "48rem",
  "4xl": "56rem",
  "5xl": "64rem",
  "6xl": "72rem",
  "7xl": "80rem",
  "full": "100%",
  "min": "min-content",
  "max": "max-content",
  "fit": "fit-content",
  "prose": "65ch",
  "screen-sm": "640px",
  "screen-md": "768px",
  "screen-lg": "1024px",
  "screen-xl": "1280px",
  "screen-2xl": "1536px",
  "screen-xs": "321px"
};
export const minHeight = {
  "0": "0px",
  "4": "1rem",
  "6": "1.5rem",
  "8": "2rem",
  "10": "2.5rem",
  "12": "3rem",
  "full": "100%",
  "screen": "100vh",
  "min": "min-content",
  "max": "max-content",
  "fit": "fit-content"
};
export const minWidth = {
  "0": "0px",
  "4": "1rem",
  "6": "1.5rem",
  "8": "2rem",
  "10": "2.5rem",
  "12": "3rem",
  "18": "4.5rem",
  "20": "5rem",
  "28": "7rem",
  "32": "8rem",
  "36": "9rem",
  "40": "10rem",
  "full": "100%",
  "min": "min-content",
  "max": "max-content",
  "fit": "fit-content"
};
export const objectPosition = {
  "bottom": "bottom",
  "center": "center",
  "left": "left",
  "left-bottom": "left bottom",
  "left-top": "left top",
  "right": "right",
  "right-bottom": "right bottom",
  "right-top": "right top",
  "top": "top"
};
export const opacity = {
  "0": "0",
  "5": "0.05",
  "10": "0.1",
  "20": "0.2",
  "25": "0.25",
  "30": "0.3",
  "40": "0.4",
  "50": "0.5",
  "60": "0.6",
  "70": "0.7",
  "75": "0.75",
  "80": "0.8",
  "90": "0.9",
  "95": "0.95",
  "100": "1"
};
export const order = {
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "8": "8",
  "9": "9",
  "10": "10",
  "11": "11",
  "12": "12",
  "first": "-9999",
  "last": "9999",
  "none": "0"
};
export const padding = {
  "0": "0px",
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "11": "2.75rem",
  "12": "3rem",
  "14": "3.5rem",
  "16": "4rem",
  "20": "5rem",
  "24": "6rem",
  "28": "7rem",
  "32": "8rem",
  "36": "9rem",
  "40": "10rem",
  "44": "11rem",
  "48": "12rem",
  "52": "13rem",
  "56": "14rem",
  "60": "15rem",
  "64": "16rem",
  "72": "18rem",
  "80": "20rem",
  "96": "24rem",
  "px": "1px",
  "0.5": "0.125rem",
  "1.5": "0.375rem",
  "2.5": "0.625rem",
  "3.5": "0.875rem"
};
export const placeholderColor = {
  "transparent": "transparent",
  "current": "currentColor",
  "black": "#000",
  "white": "#fff",
  "brandOrange": {
    "400": "#F5B457",
    "500": "#F0920C"
  },
  "brandGrey": {
    "200": "#F9FAFB",
    "400": "#686D76",
    "600": "#373A40"
  },
  "grey": {
    "50": "#FAFAFA",
    "100": "#F5F5F5",
    "200": "#EEEEEE",
    "300": "#E0E0E0",
    "400": "#BDBDBD",
    "500": "#9E9E9E",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121"
  },
  "orange": {
    "50": "",
    "100": "#FFF0CF",
    "200": "#FEC854",
    "300": "#F2994A",
    "400": "#FD9827",
    "500": "#F0920C",
    "600": "#C4691A",
    "700": "#E65100",
    "800": "",
    "900": ""
  },
  "blue": {
    "50": "#E3F2FD",
    "100": "#BADEFE",
    "200": "#69B6FF",
    "300": "#6DB8FD",
    "340": "#42A5F5",
    "350": "#2196F3",
    "400": "#2589E2",
    "500": "#1565C0",
    "600": "#0C5EB0",
    "700": "#0a549e",
    "800": "",
    "900": ""
  },
  "red": {
    "50": "#FEF1F0",
    "100": "#FFEBEE",
    "200": "#FDC8C4",
    "300": "#FBA8A2",
    "400": "#EB5757",
    "500": "#E53935",
    "600": "#D81608",
    "700": "#B71C1C",
    "800": "#B00200",
    "900": "#760C04"
  },
  "green": {
    "50": "#E9FDE7",
    "100": "#DCEDC8",
    "200": "#bfde9a",
    "300": "#9dcd65",
    "400": "#19B00C",
    "500": "#43A047",
    "600": "#179E0B",
    "700": "#558B2F",
    "800": "#117708",
    "900": "#0C5106"
  },
  "purple": {
    "50": "#F3E5F5",
    "100": "",
    "200": "#CE93D8",
    "300": "#E1BEE7",
    "400": "",
    "500": "#9C27B0",
    "600": "",
    "700": "",
    "800": "",
    "900": ""
  },
  "teal": {
    "50": "#E0F2F1",
    "100": "",
    "200": "",
    "300": "#B3E0DB",
    "400": "#26A69A",
    "500": "#009688",
    "600": "#00897B",
    "700": "",
    "800": "",
    "900": ""
  },
  "indigo": {
    "300": "#7986CB"
  },
  "pink": {
    "200": "#F48FB1"
  }
};
export const placeholderOpacity = {
  "0": "0",
  "5": "0.05",
  "10": "0.1",
  "20": "0.2",
  "25": "0.25",
  "30": "0.3",
  "40": "0.4",
  "50": "0.5",
  "60": "0.6",
  "70": "0.7",
  "75": "0.75",
  "80": "0.8",
  "90": "0.9",
  "95": "0.95",
  "100": "1"
};
export const outlineColor = {
  "transparent": "transparent",
  "current": "currentColor",
  "black": "#000",
  "white": "#fff",
  "brandOrange": {
    "400": "#F5B457",
    "500": "#F0920C"
  },
  "brandGrey": {
    "200": "#F9FAFB",
    "400": "#686D76",
    "600": "#373A40"
  },
  "grey": {
    "50": "#FAFAFA",
    "100": "#F5F5F5",
    "200": "#EEEEEE",
    "300": "#E0E0E0",
    "400": "#BDBDBD",
    "500": "#9E9E9E",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121"
  },
  "orange": {
    "50": "",
    "100": "#FFF0CF",
    "200": "#FEC854",
    "300": "#F2994A",
    "400": "#FD9827",
    "500": "#F0920C",
    "600": "#C4691A",
    "700": "#E65100",
    "800": "",
    "900": ""
  },
  "blue": {
    "50": "#E3F2FD",
    "100": "#BADEFE",
    "200": "#69B6FF",
    "300": "#6DB8FD",
    "340": "#42A5F5",
    "350": "#2196F3",
    "400": "#2589E2",
    "500": "#1565C0",
    "600": "#0C5EB0",
    "700": "#0a549e",
    "800": "",
    "900": ""
  },
  "red": {
    "50": "#FEF1F0",
    "100": "#FFEBEE",
    "200": "#FDC8C4",
    "300": "#FBA8A2",
    "400": "#EB5757",
    "500": "#E53935",
    "600": "#D81608",
    "700": "#B71C1C",
    "800": "#B00200",
    "900": "#760C04"
  },
  "green": {
    "50": "#E9FDE7",
    "100": "#DCEDC8",
    "200": "#bfde9a",
    "300": "#9dcd65",
    "400": "#19B00C",
    "500": "#43A047",
    "600": "#179E0B",
    "700": "#558B2F",
    "800": "#117708",
    "900": "#0C5106"
  },
  "purple": {
    "50": "#F3E5F5",
    "100": "",
    "200": "#CE93D8",
    "300": "#E1BEE7",
    "400": "",
    "500": "#9C27B0",
    "600": "",
    "700": "",
    "800": "",
    "900": ""
  },
  "teal": {
    "50": "#E0F2F1",
    "100": "",
    "200": "",
    "300": "#B3E0DB",
    "400": "#26A69A",
    "500": "#009688",
    "600": "#00897B",
    "700": "",
    "800": "",
    "900": ""
  },
  "indigo": {
    "300": "#7986CB"
  },
  "pink": {
    "200": "#F48FB1"
  }
};
export const outlineOffset = {
  "0": "0px",
  "1": "1px",
  "2": "2px",
  "4": "4px",
  "8": "8px"
};
export const outlineWidth = {
  "0": "0px",
  "1": "1px",
  "2": "2px",
  "4": "4px",
  "8": "8px"
};
export const ringColor = {
  "DEFAULT": "#1565C0",
  "transparent": "transparent",
  "current": "currentColor",
  "black": "#000",
  "white": "#fff",
  "brandOrange": {
    "400": "#F5B457",
    "500": "#F0920C"
  },
  "brandGrey": {
    "200": "#F9FAFB",
    "400": "#686D76",
    "600": "#373A40"
  },
  "grey": {
    "50": "#FAFAFA",
    "100": "#F5F5F5",
    "200": "#EEEEEE",
    "300": "#E0E0E0",
    "400": "#BDBDBD",
    "500": "#9E9E9E",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121"
  },
  "orange": {
    "50": "",
    "100": "#FFF0CF",
    "200": "#FEC854",
    "300": "#F2994A",
    "400": "#FD9827",
    "500": "#F0920C",
    "600": "#C4691A",
    "700": "#E65100",
    "800": "",
    "900": ""
  },
  "blue": {
    "50": "#E3F2FD",
    "100": "#BADEFE",
    "200": "#69B6FF",
    "300": "#6DB8FD",
    "340": "#42A5F5",
    "350": "#2196F3",
    "400": "#2589E2",
    "500": "#1565C0",
    "600": "#0C5EB0",
    "700": "#0a549e",
    "800": "",
    "900": ""
  },
  "red": {
    "50": "#FEF1F0",
    "100": "#FFEBEE",
    "200": "#FDC8C4",
    "300": "#FBA8A2",
    "400": "#EB5757",
    "500": "#E53935",
    "600": "#D81608",
    "700": "#B71C1C",
    "800": "#B00200",
    "900": "#760C04"
  },
  "green": {
    "50": "#E9FDE7",
    "100": "#DCEDC8",
    "200": "#bfde9a",
    "300": "#9dcd65",
    "400": "#19B00C",
    "500": "#43A047",
    "600": "#179E0B",
    "700": "#558B2F",
    "800": "#117708",
    "900": "#0C5106"
  },
  "purple": {
    "50": "#F3E5F5",
    "100": "",
    "200": "#CE93D8",
    "300": "#E1BEE7",
    "400": "",
    "500": "#9C27B0",
    "600": "",
    "700": "",
    "800": "",
    "900": ""
  },
  "teal": {
    "50": "#E0F2F1",
    "100": "",
    "200": "",
    "300": "#B3E0DB",
    "400": "#26A69A",
    "500": "#009688",
    "600": "#00897B",
    "700": "",
    "800": "",
    "900": ""
  },
  "indigo": {
    "300": "#7986CB"
  },
  "pink": {
    "200": "#F48FB1"
  }
};
export const ringOffsetColor = {
  "transparent": "transparent",
  "current": "currentColor",
  "black": "#000",
  "white": "#fff",
  "brandOrange": {
    "400": "#F5B457",
    "500": "#F0920C"
  },
  "brandGrey": {
    "200": "#F9FAFB",
    "400": "#686D76",
    "600": "#373A40"
  },
  "grey": {
    "50": "#FAFAFA",
    "100": "#F5F5F5",
    "200": "#EEEEEE",
    "300": "#E0E0E0",
    "400": "#BDBDBD",
    "500": "#9E9E9E",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121"
  },
  "orange": {
    "50": "",
    "100": "#FFF0CF",
    "200": "#FEC854",
    "300": "#F2994A",
    "400": "#FD9827",
    "500": "#F0920C",
    "600": "#C4691A",
    "700": "#E65100",
    "800": "",
    "900": ""
  },
  "blue": {
    "50": "#E3F2FD",
    "100": "#BADEFE",
    "200": "#69B6FF",
    "300": "#6DB8FD",
    "340": "#42A5F5",
    "350": "#2196F3",
    "400": "#2589E2",
    "500": "#1565C0",
    "600": "#0C5EB0",
    "700": "#0a549e",
    "800": "",
    "900": ""
  },
  "red": {
    "50": "#FEF1F0",
    "100": "#FFEBEE",
    "200": "#FDC8C4",
    "300": "#FBA8A2",
    "400": "#EB5757",
    "500": "#E53935",
    "600": "#D81608",
    "700": "#B71C1C",
    "800": "#B00200",
    "900": "#760C04"
  },
  "green": {
    "50": "#E9FDE7",
    "100": "#DCEDC8",
    "200": "#bfde9a",
    "300": "#9dcd65",
    "400": "#19B00C",
    "500": "#43A047",
    "600": "#179E0B",
    "700": "#558B2F",
    "800": "#117708",
    "900": "#0C5106"
  },
  "purple": {
    "50": "#F3E5F5",
    "100": "",
    "200": "#CE93D8",
    "300": "#E1BEE7",
    "400": "",
    "500": "#9C27B0",
    "600": "",
    "700": "",
    "800": "",
    "900": ""
  },
  "teal": {
    "50": "#E0F2F1",
    "100": "",
    "200": "",
    "300": "#B3E0DB",
    "400": "#26A69A",
    "500": "#009688",
    "600": "#00897B",
    "700": "",
    "800": "",
    "900": ""
  },
  "indigo": {
    "300": "#7986CB"
  },
  "pink": {
    "200": "#F48FB1"
  }
};
export const ringOffsetWidth = {
  "0": "0px",
  "1": "1px",
  "2": "2px",
  "4": "4px",
  "8": "8px"
};
export const ringOpacity = {
  "0": "0",
  "5": "0.05",
  "10": "0.1",
  "20": "0.2",
  "25": "0.25",
  "30": "0.3",
  "40": "0.4",
  "50": "0.5",
  "60": "0.6",
  "70": "0.7",
  "75": "0.75",
  "80": "0.8",
  "90": "0.9",
  "95": "0.95",
  "100": "1",
  "DEFAULT": "0.5"
};
export const ringWidth = {
  "0": "0px",
  "1": "1px",
  "2": "2px",
  "4": "4px",
  "8": "8px",
  "DEFAULT": "3px"
};
export const rotate = {
  "0": "0deg",
  "1": "1deg",
  "2": "2deg",
  "3": "3deg",
  "6": "6deg",
  "12": "12deg",
  "45": "45deg",
  "90": "90deg",
  "180": "180deg"
};
export const saturate = {
  "0": "0",
  "50": ".5",
  "100": "1",
  "150": "1.5",
  "200": "2"
};
export const scale = {
  "0": "0",
  "50": ".5",
  "75": ".75",
  "90": ".9",
  "95": ".95",
  "100": "1",
  "105": "1.05",
  "110": "1.1",
  "125": "1.25",
  "150": "1.5"
};
export const scrollMargin = {
  "0": "0px",
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "11": "2.75rem",
  "12": "3rem",
  "14": "3.5rem",
  "16": "4rem",
  "20": "5rem",
  "24": "6rem",
  "28": "7rem",
  "32": "8rem",
  "36": "9rem",
  "40": "10rem",
  "44": "11rem",
  "48": "12rem",
  "52": "13rem",
  "56": "14rem",
  "60": "15rem",
  "64": "16rem",
  "72": "18rem",
  "80": "20rem",
  "96": "24rem",
  "px": "1px",
  "0.5": "0.125rem",
  "1.5": "0.375rem",
  "2.5": "0.625rem",
  "3.5": "0.875rem"
};
export const scrollPadding = {
  "0": "0px",
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "11": "2.75rem",
  "12": "3rem",
  "14": "3.5rem",
  "16": "4rem",
  "20": "5rem",
  "24": "6rem",
  "28": "7rem",
  "32": "8rem",
  "36": "9rem",
  "40": "10rem",
  "44": "11rem",
  "48": "12rem",
  "52": "13rem",
  "56": "14rem",
  "60": "15rem",
  "64": "16rem",
  "72": "18rem",
  "80": "20rem",
  "96": "24rem",
  "px": "1px",
  "0.5": "0.125rem",
  "1.5": "0.375rem",
  "2.5": "0.625rem",
  "3.5": "0.875rem"
};
export const sepia = {
  "0": "0",
  "DEFAULT": "100%"
};
export const skew = {
  "0": "0deg",
  "1": "1deg",
  "2": "2deg",
  "3": "3deg",
  "6": "6deg",
  "12": "12deg"
};
export const space = {
  "0": "0px",
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "11": "2.75rem",
  "12": "3rem",
  "14": "3.5rem",
  "16": "4rem",
  "20": "5rem",
  "24": "6rem",
  "28": "7rem",
  "32": "8rem",
  "36": "9rem",
  "40": "10rem",
  "44": "11rem",
  "48": "12rem",
  "52": "13rem",
  "56": "14rem",
  "60": "15rem",
  "64": "16rem",
  "72": "18rem",
  "80": "20rem",
  "96": "24rem",
  "px": "1px",
  "0.5": "0.125rem",
  "1.5": "0.375rem",
  "2.5": "0.625rem",
  "3.5": "0.875rem"
};
export const stroke = {
  "none": "none",
  "transparent": "transparent",
  "current": "currentColor",
  "black": "#000",
  "white": "#fff",
  "brandOrange": {
    "400": "#F5B457",
    "500": "#F0920C"
  },
  "brandGrey": {
    "200": "#F9FAFB",
    "400": "#686D76",
    "600": "#373A40"
  },
  "grey": {
    "50": "#FAFAFA",
    "100": "#F5F5F5",
    "200": "#EEEEEE",
    "300": "#E0E0E0",
    "400": "#BDBDBD",
    "500": "#9E9E9E",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121"
  },
  "orange": {
    "50": "",
    "100": "#FFF0CF",
    "200": "#FEC854",
    "300": "#F2994A",
    "400": "#FD9827",
    "500": "#F0920C",
    "600": "#C4691A",
    "700": "#E65100",
    "800": "",
    "900": ""
  },
  "blue": {
    "50": "#E3F2FD",
    "100": "#BADEFE",
    "200": "#69B6FF",
    "300": "#6DB8FD",
    "340": "#42A5F5",
    "350": "#2196F3",
    "400": "#2589E2",
    "500": "#1565C0",
    "600": "#0C5EB0",
    "700": "#0a549e",
    "800": "",
    "900": ""
  },
  "red": {
    "50": "#FEF1F0",
    "100": "#FFEBEE",
    "200": "#FDC8C4",
    "300": "#FBA8A2",
    "400": "#EB5757",
    "500": "#E53935",
    "600": "#D81608",
    "700": "#B71C1C",
    "800": "#B00200",
    "900": "#760C04"
  },
  "green": {
    "50": "#E9FDE7",
    "100": "#DCEDC8",
    "200": "#bfde9a",
    "300": "#9dcd65",
    "400": "#19B00C",
    "500": "#43A047",
    "600": "#179E0B",
    "700": "#558B2F",
    "800": "#117708",
    "900": "#0C5106"
  },
  "purple": {
    "50": "#F3E5F5",
    "100": "",
    "200": "#CE93D8",
    "300": "#E1BEE7",
    "400": "",
    "500": "#9C27B0",
    "600": "",
    "700": "",
    "800": "",
    "900": ""
  },
  "teal": {
    "50": "#E0F2F1",
    "100": "",
    "200": "",
    "300": "#B3E0DB",
    "400": "#26A69A",
    "500": "#009688",
    "600": "#00897B",
    "700": "",
    "800": "",
    "900": ""
  },
  "indigo": {
    "300": "#7986CB"
  },
  "pink": {
    "200": "#F48FB1"
  }
};
export const strokeWidth = {
  "0": "0",
  "1": "1",
  "2": "2"
};
export const textColor = {
  "transparent": "transparent",
  "current": "currentColor",
  "black": "#000",
  "white": "#fff",
  "brandOrange": {
    "400": "#F5B457",
    "500": "#F0920C"
  },
  "brandGrey": {
    "200": "#F9FAFB",
    "400": "#686D76",
    "600": "#373A40"
  },
  "grey": {
    "50": "#FAFAFA",
    "100": "#F5F5F5",
    "200": "#EEEEEE",
    "300": "#E0E0E0",
    "400": "#BDBDBD",
    "500": "#9E9E9E",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121"
  },
  "orange": {
    "50": "",
    "100": "#FFF0CF",
    "200": "#FEC854",
    "300": "#F2994A",
    "400": "#FD9827",
    "500": "#F0920C",
    "600": "#C4691A",
    "700": "#E65100",
    "800": "",
    "900": ""
  },
  "blue": {
    "50": "#E3F2FD",
    "100": "#BADEFE",
    "200": "#69B6FF",
    "300": "#6DB8FD",
    "340": "#42A5F5",
    "350": "#2196F3",
    "400": "#2589E2",
    "500": "#1565C0",
    "600": "#0C5EB0",
    "700": "#0a549e",
    "800": "",
    "900": ""
  },
  "red": {
    "50": "#FEF1F0",
    "100": "#FFEBEE",
    "200": "#FDC8C4",
    "300": "#FBA8A2",
    "400": "#EB5757",
    "500": "#E53935",
    "600": "#D81608",
    "700": "#B71C1C",
    "800": "#B00200",
    "900": "#760C04"
  },
  "green": {
    "50": "#E9FDE7",
    "100": "#DCEDC8",
    "200": "#bfde9a",
    "300": "#9dcd65",
    "400": "#19B00C",
    "500": "#43A047",
    "600": "#179E0B",
    "700": "#558B2F",
    "800": "#117708",
    "900": "#0C5106"
  },
  "purple": {
    "50": "#F3E5F5",
    "100": "",
    "200": "#CE93D8",
    "300": "#E1BEE7",
    "400": "",
    "500": "#9C27B0",
    "600": "",
    "700": "",
    "800": "",
    "900": ""
  },
  "teal": {
    "50": "#E0F2F1",
    "100": "",
    "200": "",
    "300": "#B3E0DB",
    "400": "#26A69A",
    "500": "#009688",
    "600": "#00897B",
    "700": "",
    "800": "",
    "900": ""
  },
  "indigo": {
    "300": "#7986CB"
  },
  "pink": {
    "200": "#F48FB1"
  }
};
export const textDecorationColor = {
  "transparent": "transparent",
  "current": "currentColor",
  "black": "#000",
  "white": "#fff",
  "brandOrange": {
    "400": "#F5B457",
    "500": "#F0920C"
  },
  "brandGrey": {
    "200": "#F9FAFB",
    "400": "#686D76",
    "600": "#373A40"
  },
  "grey": {
    "50": "#FAFAFA",
    "100": "#F5F5F5",
    "200": "#EEEEEE",
    "300": "#E0E0E0",
    "400": "#BDBDBD",
    "500": "#9E9E9E",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121"
  },
  "orange": {
    "50": "",
    "100": "#FFF0CF",
    "200": "#FEC854",
    "300": "#F2994A",
    "400": "#FD9827",
    "500": "#F0920C",
    "600": "#C4691A",
    "700": "#E65100",
    "800": "",
    "900": ""
  },
  "blue": {
    "50": "#E3F2FD",
    "100": "#BADEFE",
    "200": "#69B6FF",
    "300": "#6DB8FD",
    "340": "#42A5F5",
    "350": "#2196F3",
    "400": "#2589E2",
    "500": "#1565C0",
    "600": "#0C5EB0",
    "700": "#0a549e",
    "800": "",
    "900": ""
  },
  "red": {
    "50": "#FEF1F0",
    "100": "#FFEBEE",
    "200": "#FDC8C4",
    "300": "#FBA8A2",
    "400": "#EB5757",
    "500": "#E53935",
    "600": "#D81608",
    "700": "#B71C1C",
    "800": "#B00200",
    "900": "#760C04"
  },
  "green": {
    "50": "#E9FDE7",
    "100": "#DCEDC8",
    "200": "#bfde9a",
    "300": "#9dcd65",
    "400": "#19B00C",
    "500": "#43A047",
    "600": "#179E0B",
    "700": "#558B2F",
    "800": "#117708",
    "900": "#0C5106"
  },
  "purple": {
    "50": "#F3E5F5",
    "100": "",
    "200": "#CE93D8",
    "300": "#E1BEE7",
    "400": "",
    "500": "#9C27B0",
    "600": "",
    "700": "",
    "800": "",
    "900": ""
  },
  "teal": {
    "50": "#E0F2F1",
    "100": "",
    "200": "",
    "300": "#B3E0DB",
    "400": "#26A69A",
    "500": "#009688",
    "600": "#00897B",
    "700": "",
    "800": "",
    "900": ""
  },
  "indigo": {
    "300": "#7986CB"
  },
  "pink": {
    "200": "#F48FB1"
  }
};
export const textDecorationThickness = {
  "0": "0px",
  "1": "1px",
  "2": "2px",
  "4": "4px",
  "8": "8px",
  "auto": "auto",
  "from-font": "from-font"
};
export const textUnderlineOffset = {
  "0": "0px",
  "1": "1px",
  "2": "2px",
  "4": "4px",
  "8": "8px",
  "auto": "auto"
};
export const textIndent = {
  "0": "0px",
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "11": "2.75rem",
  "12": "3rem",
  "14": "3.5rem",
  "16": "4rem",
  "20": "5rem",
  "24": "6rem",
  "28": "7rem",
  "32": "8rem",
  "36": "9rem",
  "40": "10rem",
  "44": "11rem",
  "48": "12rem",
  "52": "13rem",
  "56": "14rem",
  "60": "15rem",
  "64": "16rem",
  "72": "18rem",
  "80": "20rem",
  "96": "24rem",
  "px": "1px",
  "0.5": "0.125rem",
  "1.5": "0.375rem",
  "2.5": "0.625rem",
  "3.5": "0.875rem"
};
export const textOpacity = {
  "0": "0",
  "5": "0.05",
  "10": "0.1",
  "20": "0.2",
  "25": "0.25",
  "30": "0.3",
  "40": "0.4",
  "50": "0.5",
  "60": "0.6",
  "70": "0.7",
  "75": "0.75",
  "80": "0.8",
  "90": "0.9",
  "95": "0.95",
  "100": "1"
};
export const transformOrigin = {
  "center": "center",
  "top": "top",
  "top-right": "top right",
  "right": "right",
  "bottom-right": "bottom right",
  "bottom": "bottom",
  "bottom-left": "bottom left",
  "left": "left",
  "top-left": "top left"
};
export const transitionDelay = {
  "75": "75ms",
  "100": "100ms",
  "150": "150ms",
  "200": "200ms",
  "300": "300ms",
  "500": "500ms",
  "700": "700ms",
  "1000": "1000ms"
};
export const transitionDuration = {
  "75": "75ms",
  "100": "100ms",
  "150": "150ms",
  "200": "200ms",
  "300": "300ms",
  "500": "500ms",
  "700": "700ms",
  "1000": "1000ms",
  "DEFAULT": "150ms"
};
export const transitionProperty = {
  "none": "none",
  "all": "all",
  "DEFAULT": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
  "colors": "color, background-color, border-color, text-decoration-color, fill, stroke",
  "opacity": "opacity",
  "shadow": "box-shadow",
  "transform": "transform"
};
export const transitionTimingFunction = {
  "DEFAULT": "cubic-bezier(0.4, 0, 0.2, 1)",
  "linear": "linear",
  "in": "cubic-bezier(0.4, 0, 1, 1)",
  "out": "cubic-bezier(0, 0, 0.2, 1)",
  "in-out": "cubic-bezier(0.4, 0, 0.2, 1)"
};
export const translate = {
  "0": "0px",
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "11": "2.75rem",
  "12": "3rem",
  "14": "3.5rem",
  "16": "4rem",
  "20": "5rem",
  "24": "6rem",
  "28": "7rem",
  "32": "8rem",
  "36": "9rem",
  "40": "10rem",
  "44": "11rem",
  "48": "12rem",
  "52": "13rem",
  "56": "14rem",
  "60": "15rem",
  "64": "16rem",
  "72": "18rem",
  "80": "20rem",
  "96": "24rem",
  "px": "1px",
  "0.5": "0.125rem",
  "1.5": "0.375rem",
  "2.5": "0.625rem",
  "3.5": "0.875rem",
  "1/2": "50%",
  "1/3": "33.333333%",
  "2/3": "66.666667%",
  "1/4": "25%",
  "2/4": "50%",
  "3/4": "75%",
  "full": "100%"
};
export const width = {
  "0": "0px",
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "11": "2.75rem",
  "12": "3rem",
  "14": "3.5rem",
  "16": "4rem",
  "18": "4.5rem",
  "20": "5rem",
  "22": "5.5rem",
  "24": "6rem",
  "28": "7rem",
  "30": "7.5rem",
  "32": "8rem",
  "36": "9rem",
  "40": "10rem",
  "44": "11rem",
  "48": "12rem",
  "52": "13rem",
  "56": "14rem",
  "60": "15rem",
  "64": "16rem",
  "72": "18rem",
  "80": "20rem",
  "96": "24rem",
  "auto": "auto",
  "px": "1px",
  "0.5": "0.125rem",
  "1.5": "0.375rem",
  "2.5": "0.625rem",
  "3.5": "0.875rem",
  "1/2": "50%",
  "1/3": "33.333333%",
  "2/3": "66.666667%",
  "1/4": "25%",
  "2/4": "50%",
  "3/4": "75%",
  "1/5": "20%",
  "2/5": "40%",
  "3/5": "60%",
  "4/5": "80%",
  "1/6": "16.666667%",
  "2/6": "33.333333%",
  "3/6": "50%",
  "4/6": "66.666667%",
  "5/6": "83.333333%",
  "1/12": "8.333333%",
  "2/12": "16.666667%",
  "3/12": "25%",
  "4/12": "33.333333%",
  "5/12": "41.666667%",
  "6/12": "50%",
  "7/12": "58.333333%",
  "8/12": "66.666667%",
  "9/12": "75%",
  "10/12": "83.333333%",
  "11/12": "91.666667%",
  "full": "100%",
  "screen": "100vw",
  "min": "min-content",
  "max": "max-content",
  "fit": "fit-content"
};
export const willChange = {
  "auto": "auto",
  "scroll": "scroll-position",
  "contents": "contents",
  "transform": "transform"
};
export const zIndex = {
  "0": "0",
  "10": "10",
  "20": "20",
  "30": "30",
  "40": "40",
  "50": "50",
  "auto": "auto"
};