import { Overlay } from '@rneui/themed';
import { isString } from 'lodash';
import React from 'react';
import { ViewStyle } from 'react-native';
import { Box, HStack, Text, TextProps, VStack } from 'xo/core';
import { borderRadii } from 'xo/styles/restyle/border-radii';
import { colors } from 'xo/styles/restyle/colors';
import { spacing } from 'xo/styles/restyle/spacing';
import { BorderRadius, Color } from 'xo/styles/restyle/theme';
import { SvgCross } from 'xo/svg/svg-cross';
import { SvgTick } from 'xo/svg/svg-tick';
import { SvgWarning } from 'xo/svg/svg-warning';
import { Button, ButtonVariant } from './button';
import { IconButton } from './icon-button';

export interface ModalButtonProps {
  variant?: ButtonVariant;
  label: string;
  onPress: () => void;
  testID?: string;
  containerStyle?: ViewStyle;
  rounded?: BorderRadius;
  loading?: boolean;
}

export interface ModalProps {
  header?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  closeable?: boolean;
  visible: boolean;
  onClose?: () => void;
  headerProps?: TextProps;
  okProps?: ModalButtonProps;
  cancelProps?: ModalButtonProps;
  onCancel?: () => void;
  overlayStyle?: ViewStyle;
  buttonLayout?: 'vertical' | 'horizontal';
}

export const Modal = ({
  header,
  children,
  footer,
  closeable = true,
  visible,
  okProps,
  cancelProps,
  onClose,
  overlayStyle,
  buttonLayout = 'vertical',
}: ModalProps) => {
  const vertical = buttonLayout === 'vertical';

  const okButton = okProps ? (
    <Box
      w={vertical ? 'full' : undefined}
      justify="center"
      style={okProps.containerStyle}
    >
      <Button
        w="full"
        variant={okProps.variant ?? 'brand'}
        onPress={okProps.onPress}
        testID={okProps.testID}
        rounded={okProps.rounded ?? 'full'}
        loading={okProps.loading}
      >
        {okProps.label}
      </Button>
    </Box>
  ) : null;

  const cancelButton = cancelProps ? (
    <Box
      w={vertical ? 'full' : undefined}
      justify="center"
      style={cancelProps.containerStyle}
    >
      <Button
        w="full"
        variant={cancelProps.variant ?? 'brand-ghost'}
        onPress={cancelProps.onPress}
        testID={cancelProps.testID}
        rounded={cancelProps.rounded ?? 'full'}
      >
        {cancelProps.label}
      </Button>
    </Box>
  ) : null;

  return (
    <Overlay
      isVisible={visible}
      onBackdropPress={closeable ? onClose : undefined}
      overlayStyle={{
        minWidth: spacing['72'],
        padding: spacing['6'],
        borderRadius: borderRadii['2xl'],
        margin: spacing['4'],
        paddingTop: closeable ? spacing['12'] : spacing['6'],
        ...overlayStyle,
      }}
    >
      <Box position="absolute" right={0} top={0} m="2">
        {closeable && onClose ? (
          <IconButton icon={<SvgCross />} onPress={onClose} />
        ) : null}
      </Box>

      <VStack space="6" align="center" justify="center" w="full">
        {header && (
          <Text
            fontWeight="500"
            color="brandGrey.600"
            fontSize="2xl"
            textAlign="center"
          >
            {header}
          </Text>
        )}

        {isString(children) ? (
          <Text fontSize="lg" textAlign="center">
            {children}
          </Text>
        ) : (
          children
        )}

        {buttonLayout === 'horizontal' ? (
          <HStack
            w="full"
            space="2"
            direction="row"
            justify="flex-end"
            align="center"
            px="6"
            pb="6"
          >
            {cancelButton}
            {okButton}
          </HStack>
        ) : (
          <>
            {okButton}
            {cancelButton}
          </>
        )}

        {footer}
      </VStack>
    </Overlay>
  );
};

export enum ModalVariant {
  Success = 'success',
  Warning = 'warning',
}

const variantStyles: Record<
  ModalVariant,
  { okVariant: ButtonVariant; bg: Color; icon: JSX.Element }
> = {
  [ModalVariant.Success]: {
    okVariant: 'success',
    bg: 'green.50',
    icon: (
      <Box p="1" align="center" justify="center" bg="green.400" rounded="full">
        <SvgTick fill="white" scale={1.5} />
      </Box>
    ),
  },
  [ModalVariant.Warning]: {
    okVariant: 'warning',
    bg: 'red.50',
    icon: <SvgWarning scale={1.7} fill={colors['red.500']} />,
  },
};

export interface VariantModalProps {
  variant: ModalVariant;
  header: string;
  message?: string;
  visible: boolean;
  onClose: () => void;
  okProps: ModalButtonProps;
  cancelProps?: ModalButtonProps;
  buttonLayout?: ModalProps['buttonLayout'];
}

export const VariantModal = ({
  variant,
  header,
  message,
  visible,
  onClose,
  okProps,
  cancelProps,
  buttonLayout,
}: VariantModalProps) => {
  const { okVariant, bg, icon } = variantStyles[variant];

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      closeable={false}
      okProps={{
        variant: okVariant,
        rounded: 'xl',
        containerStyle:
          buttonLayout === 'vertical'
            ? {
                paddingLeft: spacing['6'],
                paddingRight: spacing['6'],
                paddingBottom: spacing['6'],
              }
            : undefined,
        ...okProps,
      }}
      cancelProps={cancelProps}
      overlayStyle={{
        borderRadius: borderRadii.sm,
        padding: 0,
        paddingTop: 0,
        maxWidth: spacing['96'],
      }}
      buttonLayout={buttonLayout}
    >
      <Box
        align="center"
        justify="center"
        h="32"
        bg={bg}
        w="full"
        borderTopRightRadius={borderRadii.lg}
        borderTopLeftRadius={borderRadii.lg}
      >
        {icon}
      </Box>
      <Box px="9" w="full">
        <Text fontSize="2xl" fontWeight="500">
          {header}
        </Text>
      </Box>
      <Box px="9" w="full">
        <Text fontSize="md" color="brandGrey.400">
          {message}
        </Text>
      </Box>
    </Modal>
  );
};

export interface WarningModalProps {
  header: string;
  message: string;
  visible: boolean;
  okProps: { onPress: () => void; label: string; loading?: boolean };
  cancelProps?: Pick<ModalButtonProps, 'onPress' | 'label'>;
  onClose: () => void;
}

export const WarningModal = ({
  header,
  message,
  visible,
  okProps,
  cancelProps,
  onClose,
}: WarningModalProps) => (
  <VariantModal
    variant={ModalVariant.Warning}
    header={header}
    message={message}
    visible={visible}
    onClose={onClose}
    okProps={okProps}
    buttonLayout="horizontal"
    cancelProps={{
      variant: 'outline-warning',
      rounded: 'xl',
      label: cancelProps?.label || 'Cancel',
      onPress: cancelProps?.onPress ?? onClose,
      ...cancelProps,
    }}
  />
);

export interface SuccessModalProps {
  header: string;
  message?: string;
  visible: boolean;
  onClose: () => void;
}

export const SuccessModal = ({
  header,
  message,
  visible,
  onClose,
}: SuccessModalProps) => (
  <VariantModal
    variant={ModalVariant.Success}
    header={header}
    message={message}
    visible={visible}
    onClose={onClose}
    okProps={{
      onPress: onClose,
      label: 'Close',
      containerStyle: {
        paddingHorizontal: spacing['6'],
        paddingBottom: spacing['6'],
      },
    }}
  />
);
