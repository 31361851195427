import { ApiSiteKind } from 'xo/graphql/api/enums/site-kind.generated';
import { useCurrentUser, useSites } from 'xo/login/current-user-hooks';
import {
  appRoutes,
  arrivalDashboardRoutes,
  consignmentNoteRoutes,
  transportRoutes,
} from 'xo/navigation/web-routes';
import { SvgAdd } from 'xo/svg/svg-add';
import { SvgBarChart } from 'xo/svg/svg-bar-chart';
import { SvgCalendarEvent } from 'xo/svg/svg-calendar-event';
import { SvgCalendarPerson } from 'xo/svg/svg-calendar-person';
import { SvgClock } from 'xo/svg/svg-clock';
import { SvgCog } from 'xo/svg/svg-cog';
import { SvgDelivery } from 'xo/svg/svg-delivery';
import { SvgHelp } from 'xo/svg/svg-help';
import { SvgHouse } from 'xo/svg/svg-house';
import { SvgKey } from 'xo/svg/svg-key';
import { SvgLocation } from 'xo/svg/svg-location';
import { SvgMapArrow } from 'xo/svg/svg-map-arrow';
import { SvgPaper } from 'xo/svg/svg-paper';
import { SvgPerson } from 'xo/svg/svg-person';
import { SvgSignOut } from 'xo/svg/svg-sign-out';
import { SvgSpeechBubble } from 'xo/svg/svg-speech-bubble';
import { SvgTruckFilled } from 'xo/svg/svg-truck-filled';
import { SvgTruckFront } from 'xo/svg/svg-truck-front';
import { SvgTruckSchedule } from 'xo/svg/svg-truck-schedule';
import { SvgViewModule } from 'xo/svg/svg-view-module';
import { SvgWarning } from 'xo/svg/svg-warning';
import { QUARANTINE_MATRIX_SITE_KINDS } from '../app/app-constants';
import { openSupportModal } from '../app/utils/support-utils';
import { getPermissions } from '../utils/permissions';
import { useOnSignOut } from './auth-hooks';
import { getUserShouldNotSeeSchedule } from './auth-utils';
import { useIsXoAdmin } from './shared-hooks';

export interface NavigationOption {
  label: string;
  to?: string;
  icon: JSX.Element;
  onClick?: () => void;
  // whether the option is hidden. if a parent option is hidden, all nested options are automatically hidden
  admin?: boolean;
  // nested options
  nested?: Omit<NavigationOption, 'nested'>[];
  // after onClick is called, don't automatically close
  suppressCloseOnClick?: boolean;
  // only render as active when there's an exact route match
  activeExact?: boolean;
}

export const useNavigationOptions = () => {
  const sites = useSites();
  const user = useCurrentUser();
  const permissions = getPermissions(user);
  const onSignOut = useOnSignOut();
  const isXoAdmin = useIsXoAdmin();

  const top: NavigationOption[] = [
    {
      label: 'Schedule',
      to: appRoutes.schedule,
      icon: <SvgCalendarEvent />,
      admin: !permissions.visitor || getUserShouldNotSeeSchedule(user),
    },
    {
      label: 'New visit',
      to: appRoutes.visits.new,
      icon: <SvgAdd />,
      admin: !permissions.visitor?.visitorPrebooking,
    },
    {
      label: 'New delivery',
      to: appRoutes.deliveries.new,
      icon: <SvgDelivery />,
      admin: !permissions.visitor?.deliveryPrebooking,
    },
    {
      label: 'Visitor log',
      to: appRoutes.reporting.visitorLog,
      icon: <SvgViewModule />,
      admin: !permissions?.visitor,
    },
    {
      label: 'Transport schedule',
      to: transportRoutes.root,
      icon: <SvgTruckSchedule />,
      admin: !permissions.transport?.transportScheduling,
    },
    {
      label: 'Consignment notes',
      to: consignmentNoteRoutes.root,
      icon: <SvgPaper />,
      admin: !permissions.transport?.consignmentNote,
    },
    {
      label: 'Arrival dashboard',
      to: arrivalDashboardRoutes.root,
      icon: <SvgTruckFront />,
      admin: !permissions.transport?.arrivalDashboard,
    },
    {
      label: 'Arrival metrics',
      to: appRoutes.arrivalMetrics,
      icon: <SvgBarChart />,
      admin: !permissions.transport?.arrivalDashboard,
    },
  ];

  const bottom: NavigationOption[] = [
    {
      label: 'Admin',
      icon: <SvgKey />,
      admin: !isXoAdmin,
      nested: [
        {
          label: 'System',
          icon: <SvgCog />,
          to: appRoutes.admin.system,
        },
        {
          label: 'Dashboard',
          icon: <SvgBarChart />,
          to: appRoutes.admin.dashboard,
        },
        {
          label: 'Organisations',
          icon: <SvgHouse />,
          to: appRoutes.admin.organisations,
        },
        {
          label: 'All sites',
          icon: <SvgLocation />,
          to: appRoutes.admin.allSites,
        },
        {
          label: 'All users',
          icon: <SvgPerson />,
          to: appRoutes.admin.allUsers,
        },
        {
          label: 'Outbreaks',
          icon: <SvgWarning />,
          to: appRoutes.admin.outbreaks,
        },
        {
          label: 'Geofences',
          icon: <SvgMapArrow />,
          to: appRoutes.admin.geofences,
        },
        {
          label: 'Vehicles',
          icon: <SvgTruckFilled />,
          to: appRoutes.admin.vehicles,
        },
        {
          label: 'Vehicle events',
          icon: <SvgTruckFilled />,
          to: appRoutes.admin.vehicleEvents,
        },
      ],
    },
    {
      label: 'Settings',
      icon: <SvgCog />,
      admin: !!user.airtableDriverId,
      nested: [
        {
          label: 'Users',
          icon: <SvgPerson />,
          to: appRoutes.settings.users,
          admin: !!user.airtableDriverId,
        },
        {
          label: 'Notifications',
          icon: <SvgSpeechBubble />,
          to: appRoutes.settings.notifications,
          admin: !!user.airtableDriverId,
        },
        {
          label: 'Sites',
          icon: <SvgLocation />,
          to: appRoutes.settings.sites,
          admin: !permissions.visitor,
        },

        // TODO: remove this once the new sites page is fully functional
        {
          label: 'Sites (New)',
          icon: <SvgLocation />,
          to: appRoutes.settings.sitesUrql,
          admin: true,
        },

        {
          label: 'Quarantine',
          icon: <SvgClock />,
          to: appRoutes.settings.quarantine,
          admin:
            !sites.some(
              s => QUARANTINE_MATRIX_SITE_KINDS[s.kind as ApiSiteKind],
            ) || !permissions.visitor,
        },
        {
          label: 'Contacts',
          icon: <SvgCalendarPerson />,
          to: appRoutes.settings.addressBook.contacts,
          admin: !permissions.transport?.consignmentNote,
        },
        {
          label: 'Locations',
          icon: <SvgLocation />,
          to: appRoutes.settings.addressBook.locations,
          admin: !permissions.transport?.consignmentNote,
        },
        {
          label: 'Vehicles',
          icon: <SvgTruckFilled />,
          to: appRoutes.settings.vehicles,
          admin: !permissions.transport?.consignmentNote,
        },
      ],
    },
    {
      label: 'Support',
      icon: <SvgHelp />,
      onClick: openSupportModal,
    },
    {
      label: 'Log out',
      icon: <SvgSignOut />,
      onClick: onSignOut,
    },
  ];

  const flattenOptions = (options: NavigationOption[]): NavigationOption[] =>
    options.concat(
      options
        .filter(o => !!o.nested?.length)
        .flatMap(o => flattenOptions(o.nested!)),
    );

  const optionMap = new Map(
    flattenOptions(top.concat(bottom))
      .filter(o => o.to)
      .map(o => [o.to!, o]),
  );

  return {
    top,
    bottom,
    optionMap,
  };
};
