import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { countryCodeOptions } from 'xo/constants';
import {
  useCurrentUser,
  useRefetchCurrentUser,
} from 'xo/login/current-user-hooks';
import { IsValidPhoneNumberParams, isValidPhoneNumber } from 'xo/validation';
import {
  UserListResponse,
  UserPasswordResetResponse,
  UserResponse,
  UserTermsAndPrivacyRequest,
} from '../api-models';
import { notify } from '../app/notifications';
import {
  CovidVaccinationCertificateSightingModel,
  UserModel,
  VisitQuestionnaireModel,
} from '../models/visitor-log-models';
import { getApi } from './axios-instance';
import { covidVaccinationSightingTransformer } from './network-transformers';
import { readUserTransformer, readUsersTransformer } from './transformers';

const userApi = getApi('user');
const userOpenApi = getApi('user', { open: true });

export const userQueryKeys = {
  getUsers: (kind: 'host' | 'visitor') => `getUsers:${kind}`,
  user: (id: string) => `user:${id}`,
};

export const useGetUsers = (kind: 'host' | 'visitor') => {
  const currentUser = useCurrentUser();
  return useQuery<UserModel[]>(
    userQueryKeys.getUsers(kind),
    () =>
      userApi
        .get<UserListResponse>('/', { params: { kind } })
        .then(response =>
          readUsersTransformer(
            response.data.users,
            currentUser?.organisation?.id,
          ),
        ),
    {
      cacheTime: 0,
    },
  );
};

export const useGetUser = (id: string, enabled?: boolean) =>
  useQuery(
    userQueryKeys.user(id),
    () =>
      userApi
        .get<UserResponse>(id)
        .then(response => readUserTransformer(response.data.user, undefined)),
    { enabled },
  );

export const usePostTermsAgreementUnauthed = (
  timestamp: string,
  questionnaireCacheKey?: string,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ userId }: { userId: string }) => {
      const data: UserTermsAndPrivacyRequest = {
        termsVersion: timestamp,
        privacyVersion: timestamp,
      };

      return userOpenApi
        .post<UserResponse>(`/${userId}/terms-and-privacy`, data)
        .then(response => {
          const questionnaire = questionnaireCacheKey
            ? queryClient.getQueryData<VisitQuestionnaireModel>(
                questionnaireCacheKey,
              )
            : undefined;

          const visitor = readUserTransformer(
            response.data.user,
            questionnaire?.visit.site.organisation.id!,
          );

          if (questionnaireCacheKey && questionnaire) {
            queryClient.setQueryData<VisitQuestionnaireModel>(
              questionnaireCacheKey,
              {
                ...questionnaire,
                visit: {
                  ...questionnaire.visit,
                  visitor,
                },
              },
            );
          }

          return visitor;
        });
    },
    { retry: false },
  );
};

export const usePostTermsAgreementAuthed = () => {
  const refetchCurrentUser = useRefetchCurrentUser();

  return useMutation(
    () => {
      const timestamp = dayjs().toISOString();
      const data: UserTermsAndPrivacyRequest = {
        termsVersion: timestamp,
        privacyVersion: timestamp,
      };

      return userApi
        .post<UserResponse>(`/me/terms-and-privacy`, data)
        .then(response => {
          refetchCurrentUser();

          return response.data;
        });
    },
    { retry: false },
  );
};

export const usePostUserCovidVaccinationSighting = () =>
  useMutation(
    (data: CovidVaccinationCertificateSightingModel & { id: string }) => {
      return userApi.post(
        `/${data.id}/covid-vaccination-sighting`,
        covidVaccinationSightingTransformer(data),
      );
    },
  );

export const usePostTriggerPasswordReset = () =>
  useMutation((request: { id: string }) => {
    return userApi
      .post<UserPasswordResetResponse>(`/${request.id}/trigger-password-reset`)
      .then((r: AxiosResponse<UserPasswordResetResponse>) => r.data);
  });

export const useGetIsValidPhoneNumber = ({
  phone,
  requireMobile,
}: Omit<IsValidPhoneNumberParams, 'countryAbbr'>) => {
  const countryAbbr = countryCodeOptions.find(o =>
    phone?.startsWith(o.label),
  )?.value;

  const params = {
    phone,
    requireMobile,
    countryAbbr: countryAbbr!,
  };

  const { data, isFetching } = useQuery(
    [params.countryAbbr, params.phone, params.requireMobile],
    () => isValidPhoneNumber({ ...params, onError: notify.error }),
    {
      enabled: Boolean(phone && countryAbbr),
      staleTime: Number.POSITIVE_INFINITY,
    },
  );

  return {
    valid: data,
    validating: isFetching,
  };
};
