import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useMemo } from 'react';
import { confirm } from 'xo/components/web-modal';
import { formatDateOrTime } from 'xo/date-utils';
import { useOnClearConfirm } from 'xo/hooks/component-hooks';
import { InputVariant } from 'xo/models';
import { colors, height } from 'xo/styles/tailwind-theme';
import { SvgCalendarToday } from 'xo/svg/svg-calendar-today';
import { SvgClock } from 'xo/svg/svg-clock';
import { ClearButton } from './clear-button';
import { ConfirmClearProps } from './component-models';
import './date-time-picker.overrides.css';
import Flatpickr from './flatpickr';
import { Input } from './input-web';

export interface DateTimePickerProps {
  id?: string;
  picker?: 'date' | 'time';
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (date?: Dayjs) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  value?: Dayjs;
  maxDate?: Dayjs;
  minDate?: Dayjs;
  icon?: boolean;
  allowClear?: boolean | ConfirmClearProps;
  extraRight?: JSX.Element;
  onClose?: () => void;
  onOpen?: () => void;
  variant?: InputVariant;
  renderSelected?: (value: Dayjs) => string;
  ariaLabel?: string;
}

/**
 * @deprecated Don't use this directly, use DatePicker or TimePicker from xo/forms instead, and augment with any additional required functionality from DateTimePicker in client/web/components
 */
export const DateTimePickerWeb: React.FC<DateTimePickerProps> = ({
  id,
  className,
  picker = 'date',
  placeholder,
  disabled,
  onChange,
  value,
  onFocus,
  onBlur,
  maxDate,
  minDate,
  icon = true,
  allowClear,
  extraRight,
  onClose,
  onOpen,
  variant,
  renderSelected,
  ariaLabel,
}) => {
  const onChangeCb = useCallback(
    (dates?: Date[]) => {
      if (dates && dates[0]) {
        const newValue = dayjs(dates[0]);
        onChange && onChange(newValue);
      } else {
        onChange && onChange(undefined);
      }
    },
    [onChange],
  );

  const onClear = useOnClearConfirm({
    clearable: allowClear,
    onChange: onChangeCb,
    onBlur,
    confirm,
  });

  const variantStyles: React.CSSProperties = {
    paddingLeft: 0,
    fontWeight: '600',
    fontSize: '18px',
  };
  const style: React.CSSProperties | undefined =
    variant === InputVariant.Bare
      ? {
          borderColor: 'transparent',
          backgroundColor: 'transparent',
          ...variantStyles,
        }
      : variant === InputVariant.Editable
        ? {
            borderColor: colors.grey[300],
            backgroundColor: colors.grey[100],
            ...variantStyles,
          }
        : undefined;

  const onOpenCb = () => {
    onOpen && onOpen();
    onFocus && onFocus();
  };

  const onCloseCb = () => {
    onClose && onClose();
    onBlur && onBlur();
  };

  const format = useCallback(
    (v: Dayjs) => {
      if (renderSelected) {
        return renderSelected(v);
      }

      return formatDateOrTime(dayjs(v), picker);
    },
    [renderSelected, picker],
  );

  return (
    <div id={id} className="relative">
      <Flatpickr
        options={{
          formatDate: (v: Date) => format(dayjs(v)),
          ...(picker === 'date'
            ? {
                enableTime: false,
                monthSelectorType: 'static',
                defaultDate: new Date(),
                maxDate: maxDate?.endOf('day').toDate(),
                minDate: minDate?.startOf('day').toDate(),
                disableMobile: true,
              }
            : {
                enableTime: true,
                noCalendar: true,
                time_24hr: false,
                enableSeconds: false,
              }),
        }}
        onChange={onChangeCb}
        onClose={onCloseCb}
        onOpen={onOpenCb}
        value={useMemo(() => value?.toDate(), [value])}
        disabled={disabled}
        render={(_: any, ref: (node: HTMLInputElement | null) => void) => (
          <Input
            aria-label={ariaLabel}
            inputRef={ref}
            className={className}
            style={{
              minWidth: '120px',
              height: picker === 'time' ? height[10] : undefined,
              ...style,
            }}
            placeholder={placeholder ? placeholder : `Select ${picker}`}
            disabled={disabled}
            prefix={
              icon ? (
                picker === 'date' ? (
                  <SvgCalendarToday />
                ) : (
                  <SvgClock />
                )
              ) : undefined
            }
            value={value ? format(value) : ''}
            data-e2e-hide
          />
        )}
      />
      {allowClear && value && !disabled ? (
        <ClearButton
          buttonClassName="absolute right-0 top-0"
          crossClassName="m-3 w-4 h-4"
          onClick={onClear}
        />
      ) : undefined}
      {extraRight && (
        <div className="absolute right-0" style={{ top: '-36px' }}>
          {extraRight}
        </div>
      )}
    </div>
  );
};
